import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useWatch, useFormContext } from 'react-hook-form';
import { FdIcons, FdTypography } from '@fifthdomain/fe-shared';
import useDragDrop from '../../../hooks/useDragDrop';

const OrderingAnswer = ({ qIdx, cIdx, swapChoice }) => {
  const { control, setValue, getValues } = useFormContext();
  const watchChoice = useWatch({
    control,
    name: `questions[${qIdx}].multipleChoices[${cIdx}]`,
  });

  const { DragIndicator } = FdIcons;
  const ref = useRef(null);
  const moveCard = (dragIdx, hoverIdx) => {
    const sourceOrder = getValues(
      `questions[${qIdx}].multipleChoices[${dragIdx}].order`,
    );
    const destinationOrder = getValues(
      `questions[${qIdx}].multipleChoices[${hoverIdx}].order`,
    );
    // interchange order
    if (sourceOrder && destinationOrder && sourceOrder !== destinationOrder) {
      setValue(
        `questions[${qIdx}].multipleChoices[${hoverIdx}].order`,
        sourceOrder,
      );
      setValue(
        `questions[${qIdx}].multipleChoices[${dragIdx}].order`,
        destinationOrder,
      );
      swapChoice(dragIdx, hoverIdx);
    }
  };

  const { handlerId, isDragging, drag, drop } = useDragDrop({
    moveCard,
    ref,
    dragIdx: watchChoice?.id,
    dropIdx: cIdx,
  });
  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  return (
    <Box
      mb={2}
      display="flex"
      alignItems="center"
      width="100%"
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <Box mr={2} style={{ cursor: 'pointer' }}>
        <DragIndicator />
      </Box>
      <Box mr={2} width="100%">
        <FdTypography variant="body1">{watchChoice?.answer}</FdTypography>
      </Box>
    </Box>
  );
};

OrderingAnswer.propTypes = {
  qIdx: PropTypes.number.isRequired,
  cIdx: PropTypes.number.isRequired,
  swapChoice: PropTypes.func.isRequired,
};

export default OrderingAnswer;
