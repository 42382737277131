import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import {
  FdTypography,
  FdIcons,
  FdProgress,
  useQueryRecursive,
  FdAutocomplete,
  FdChip,
} from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { listModulePartProgresses } from '../../../graphql/queries';
import InsightsTabs from './InsightsTab';
import PerformanceInsight from './PerformanceInsight';
import ProgressInsight from './ProgressInsight';
import EmptyDataImage from '../../../shared/images/empty-data.svg';
import { sortDataAlphabetically } from '../../../shared/utils/stringUtils';

const { SupervisorAccount, PieChartTwoTone, Timeline: TimelineIcon } = FdIcons;
const useStyles = makeStyles((theme) => ({
  imageColor: {
    color: theme.palette.iconography.placeholder,
  },
  flex: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));
const ViewInsight = ({ courseUsersGroupsData, courseData }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  const courseUsers = courseUsersGroupsData?.getCourse?.courseUsers?.items?.map(
    (item) => ({
      id: item.id,
      userId: item.user.id,
      email: item.user.email,
      name: item.user.name,
      courseUserId: item.id,
      groups:
        item.user?.groups?.items?.length > 0
          ? item.user?.groups?.items?.map((userGroup) => userGroup?.group?.name)
          : ['Ungrouped'],
    }),
  );

  const classes = useStyles();
  const { data: modulePartProgressData, loading: modulePartProgressLoading } =
    useQueryRecursive(gql(listModulePartProgresses), {
      variables: {
        limit: 100000,
      },
    });

  const courseModulePartProgress =
    modulePartProgressData?.listModulePartProgresses?.items
      ?.filter(
        (item) => item?.courseUser?.courseId === courseData?.getCourse?.id,
      )
      ?.filter((moduleStatus) => moduleStatus.status !== 'NOT_STARTED');

  const modulePartData = courseModulePartProgress
    ?.filter(
      (courseUserModulePart) =>
        courseUserModulePart?.courseUserId === selectedUser?.courseUserId,
    )
    ?.map(
      (item) =>
        item.modulePart && {
          courseModuleId: item.modulePart.courseModuleId,
          courseModulePartsId: item.modulePartId,
          labId: item.modulePart.labId,
          type: item.modulePart.type,
          quizId: item.modulePart.quizId,
          name: item.modulePart.name,
          status: item.status,
        },
    );

  const data = [
    {
      heading: 'Performance',
      subHeading: 'How do they compare to others?',
      index: 0,
      data: (
        <PerformanceInsight
          courseModulePartProgress={courseModulePartProgress}
          modulePartData={modulePartData}
          totalPoolUsers={courseUsers?.length}
          courseData={courseData?.getCourse?.courseModules?.items}
        />
      ),
    },
    {
      heading: 'Progress',
      subHeading: 'How are they progressing through the course?',
      index: 1,
      data: (
        <ProgressInsight
          userId={selectedUser?.userId}
          courseUserId={selectedUser?.courseUserId}
          modulePartData={modulePartData}
          courseData={courseData}
        />
      ),
    },
  ];
  if (modulePartProgressLoading) {
    return <FdProgress />;
  }

  return (
    <Box width="calc(100%-200px)" data-cy="insights-page">
      <Box
        display="flex"
        justifyContent="flex-end"
        marginBottom={2}
        marginTop="-75px"
      >
        <FdAutocomplete
          id="course users"
          label=""
          placeholder="Select/Search a User or Group"
          options={sortDataAlphabetically(courseUsers, 'name')}
          width="500px"
          optionLabel="name"
          optionValue="id"
          defaultSelected={[]}
          renderOption={(option) => {
            return (
              <Box display="flex" flexDirection="column" width="100%">
                <Box display="flex" flexDirection="column" mb={1}>
                  <FdTypography variant="body1">{option?.name}</FdTypography>
                  <FdTypography variant="caption" color="secondary">
                    {option?.email}
                  </FdTypography>
                </Box>
                <Box className={classes.flex}>
                  {option?.groups?.map((group) => (
                    <FdChip
                      label={group}
                      size="small"
                      color="default"
                      rounded={false}
                    />
                  ))}
                </Box>
              </Box>
            );
          }}
          onChange={(user) => {
            setSelectedUser(user);
          }}
        />
      </Box>

      {selectedUser ? (
        courseModulePartProgress.length > 0 ? (
          <InsightsTabs data={data} />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop={30}
          >
            <Box my={2}>
              <img src={EmptyDataImage} alt="empty-data" />
            </Box>
            <FdTypography variant="h3" data-cy="insights-message">
              No Data To Display
            </FdTypography>
          </Box>
        )
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop={30}
        >
          <Box display="flex" flexDirection="row">
            <TimelineIcon className={classes.imageColor} />
            <PieChartTwoTone className={classes.imageColor} />
          </Box>
          <SupervisorAccount
            className={classes.imageColor}
            style={{
              fontSize: 100,
            }}
          />
          <FdTypography variant="h3" data-cy="insights-message">
            No User Selected
          </FdTypography>
          <FdTypography
            variant="body1"
            color="secondary"
            data-cy="insights-no-attempts"
          >
            Select a Group and User at the top right of this page to view their
            insights
          </FdTypography>
        </Box>
      )}
    </Box>
  );
};
ViewInsight.propTypes = {
  courseUsersGroupsData: PropTypes.shape({
    getCourse: PropTypes.shape({
      courseUsers: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      coursegroups: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
  courseData: PropTypes.shape({
    getCourse: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      status: PropTypes.string,
      courseModules: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
};

ViewInsight.defaultProps = {
  courseUsersGroupsData: {},
  courseData: [],
};

export default ViewInsight;
