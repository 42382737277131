import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdTypography,
  FdAccordion,
  FdCheckbox,
  FdTextField,
} from '@fifthdomain/fe-shared';
import { VM_NETWORK_OPTIONS } from '../../constants';

const ViewVirtualMachines = ({ heading, subHeading, values }) => {
  return (
    <FdCard heading={heading} subHeading={subHeading} variant="outlined">
      <Box display="flex" flexDirection="column">
        {values?.labVms?.map((labVm) => (
          <Box mt={2} key={labVm.id}>
            <FdAccordion
              open
              startAdornment
              showToggleButton={false}
              summary={() => (
                <Box display="flex" flexDirection="row">
                  <FdTypography variant="h3">Virtual Machine</FdTypography>
                </Box>
              )}
              content={() => (
                <>
                  <Box mt={2} mb={2}>
                    <FdTypography variant="subtitle1">VM Name</FdTypography>
                    <FdTypography variant="body1" color="secondary">
                      {labVm?.name}
                    </FdTypography>
                  </Box>
                  <Box mt={2} mb={2}>
                    <FdTypography variant="subtitle1">VM Image</FdTypography>
                    <FdTypography variant="body1" color="secondary">
                      {labVm?.template}
                    </FdTypography>
                  </Box>
                  <Box mt={2} mb={2}>
                    <FdTypography variant="subtitle1">
                      Network Options
                    </FdTypography>
                    <FdCheckbox
                      options={VM_NETWORK_OPTIONS}
                      multiple
                      disabled
                      defaultSelected={
                        (labVm?.networkOptions?.includes('captureVmUserEvents')
                          ? labVm?.networkOptions.push(
                              'customConfigurations',
                            ) && labVm?.networkOptions
                          : labVm?.networkOptions) ||
                        Object.keys(labVm).filter((key) => labVm[key] === true)
                      }
                    />
                  </Box>
                  <FdCard optional heading="Assign Networks to VMs">
                    <Box display="flex" flexDirection="column">
                      {labVm?.networks?.items?.map((labNetworkVm) => (
                        <Box mt={2} key={labNetworkVm.id}>
                          <FdAccordion
                            open
                            startAdornment
                            showToggleButton={false}
                            summary={() => (
                              <Box display="flex" flexDirection="row">
                                <FdTypography variant="h3">
                                  Network
                                </FdTypography>
                              </Box>
                            )}
                            content={() => (
                              <>
                                <Box mt={2} mb={2}>
                                  <FdTypography variant="subtitle1">
                                    Network
                                  </FdTypography>
                                  <FdTypography
                                    variant="body1"
                                    color="secondary"
                                  >
                                    {labNetworkVm?.name}
                                  </FdTypography>
                                </Box>
                                <Box mt={2} mb={2}>
                                  <FdTypography variant="subtitle1">
                                    NIC Adapter Type
                                  </FdTypography>
                                  <FdTextField
                                    id="nicAdapter"
                                    value={
                                      labNetworkVm?.adapterType || 'VMXNET3'
                                    }
                                    type="string"
                                    required
                                    width="192px"
                                    disabled
                                  />
                                </Box>
                                <Box mt={2} mb={2}>
                                  <FdTypography variant="subtitle1">
                                    IP Address (IPV4) for VM (optional)
                                  </FdTypography>
                                  <FdTextField
                                    id="ip"
                                    value={labNetworkVm?.ip}
                                    type="string"
                                    width="406px"
                                    disabled
                                  />
                                </Box>
                                <Box mt={2} mb={2}>
                                  <FdTypography variant="subtitle1">
                                    MAC Address for VM (optional)
                                  </FdTypography>
                                  <FdTextField
                                    id="mac"
                                    value={labNetworkVm?.mac}
                                    type="string"
                                    width="406px"
                                    disabled
                                  />
                                </Box>
                              </>
                            )}
                          />
                        </Box>
                      ))}
                    </Box>
                  </FdCard>
                </>
              )}
            />
          </Box>
        ))}
      </Box>
    </FdCard>
  );
};

ViewVirtualMachines.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subHeading: PropTypes.string,
  values: PropTypes.shape({
    labVms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        template: PropTypes.string,
        hasInternet: PropTypes.bool,
        hasVdi: PropTypes.bool,
      }),
    ),
  }).isRequired,
};

ViewVirtualMachines.defaultProps = {
  heading: '',
  subHeading: '',
};

export default ViewVirtualMachines;
