import React from 'react';
import PropTypes from 'prop-types';
import Summary from '../Summary/Summary';

function CourseSummary({ data }) {
  return <Summary data={data} divider={false} />;
}

CourseSummary.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CourseSummary;
