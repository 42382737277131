import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Box } from '@material-ui/core';
import {
  FdChatDrawer,
  useSnapshot,
  globalStore,
  useQueryRecursive,
  FdOrgLogo,
  FdModal,
} from '@fifthdomain/fe-shared';
import { createCourseMessage, readChatMessages } from '../../graphql/mutations';
import { errorToastMessage } from '../../shared/utils/toast';
import { listCourseMessagesByUserCourseId } from '../../graphql/queries';
import setAppMarginRightByIds from '../../shared/utils/layout';
import { ChatContext } from '../../contexts';
import ChatSaveModal from './ChatSaveModal';

const CourseChat = ({
  openChat,
  onCloseChat,
  chatTitle,
  isAdmin,
  receiverId,
  courseId,
  userCourseId,
}) => {
  const globalSnap = useSnapshot(globalStore);
  const [refreshQueries, setRefreshQueries] = useState(false);
  const { isChatDirty, setIsChatDirty } = useContext(ChatContext);
  const [previousPathname] = useState(window.location.pathname);
  const [showChatSave, setShowChatSave] = useState(false);
  const history = useHistory();

  const { data: messagesData, loading: messagesLoading } = useQueryRecursive(
    gql(listCourseMessagesByUserCourseId),
    {
      variables: {
        userCourseId,
      },
      skip: !userCourseId,
    },
  );
  const [markAsReadChatMessagesMutation] = useMutation(gql(readChatMessages));

  const [createCourseMessageMutation] = useMutation(gql(createCourseMessage), {
    onCompleted: () => {
      setRefreshQueries(false);
      if (isAdmin) {
        const unReadMessageCount =
          messagesData?.listCourseMessagesByUserCourseId?.items.filter(
            (m) => !m?.sender?.permissions?.includes('VIEW_INSIGHTS'),
          ).length || 0;
        // unReadMessageCount so consider the admin replied and messages are answered
        // and no more unread messages - flag all messages with read status
        if (unReadMessageCount > 0) {
          markAsReadChatMessagesMutation({
            variables: { userCourseId },
            senderId: receiverId,
          });
          // refresh calling page queries
          setRefreshQueries(true);
        }
      }
    },
    onError: () => {
      errorToastMessage('Error: cannot submit message');
    },
  });

  const addChatMessage = ({ message, dateSubmitted }) => {
    // create a message in db
    createCourseMessageMutation({
      variables: {
        input: {
          createdAt: dateSubmitted,
          updatedAt: dateSubmitted,
          message,
          seen: 'FALSE',
          senderId: globalSnap.userId,
          receiverId: isAdmin ? receiverId : undefined,
          userCourseId,
          courseId,
          orgId: globalSnap.orgId,
        },
      },
    });
  };

  const chatHistory =
    messagesData?.listCourseMessagesByUserCourseId?.items.map((m) => ({
      name: isAdmin ? chatTitle : globalSnap.name,
      message: m.message,
      createdAt: m.createdAt,
      avatarImage:
        isAdmin && m?.sender?.permissions?.includes('VIEW_INSIGHTS') ? (
          <FdOrgLogo isIcon />
        ) : (
          !isAdmin &&
          m?.sender?.permissions?.includes('VIEW_INSIGHTS') && (
            <FdOrgLogo isIcon />
          )
        ),
      senderName: m.sender?.name === globalSnap.name ? 'You' : m.sender?.name,
    })) || [];

  return (
    <Box>
      <FdChatDrawer
        openChat={openChat}
        onCloseChat={() => {
          if (isChatDirty) {
            setShowChatSave(true);
            return;
          }
          onCloseChat(refreshQueries);
        }}
        onAddChatMessage={addChatMessage}
        chatTitle={chatTitle}
        parentAppId="labs-fe"
        chatHistory={chatHistory}
        chatAuthorName={globalSnap.name}
        showAvatarOnTitle={isAdmin}
        loading={messagesLoading}
        avatarOrgLogoOnNewMessage={isAdmin && <FdOrgLogo isIcon />}
        isDirty={(_isDirty) => {
          setIsChatDirty(_isDirty);
        }}
      />
      <ChatSaveModal
        onConfirm={() => {
          setIsChatDirty(false);
          setShowChatSave(false);
          onCloseChat(refreshQueries);
        }}
        onCancel={() => {
          setShowChatSave(false);
        }}
        open={showChatSave}
      />
      <NavigationPrompt
        when={(currentLocation, nextLocation) =>
          (isChatDirty &&
            currentLocation?.pathname &&
            !nextLocation?.pathname) ||
          (isChatDirty && currentLocation?.pathname !== nextLocation?.pathname)
        }
        afterCancel={() => {
          if (window.location.pathname !== previousPathname) {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsent message(s). If you leave now, your message(s) will be lost. Are you sure if you want to proceed?"
            confirm="Stay"
            dismiss="Leave and discard message"
            open
            onConfirm={onCancel}
            onDismiss={() => {
              setIsChatDirty(false);
              setAppMarginRightByIds(['topnav', 'labs-fe'], 0);
              onConfirm();
            }}
            data-cy="leave-modal"
          />
        )}
      </NavigationPrompt>
    </Box>
  );
};

CourseChat.propTypes = {
  openChat: PropTypes.bool.isRequired,
  onCloseChat: PropTypes.func.isRequired,
  chatTitle: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  receiverId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  userCourseId: PropTypes.string.isRequired,
};

CourseChat.defaultProps = {
  isAdmin: false,
};

export default CourseChat;
