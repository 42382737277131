import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  makeStyles,
} from '@material-ui/core';
import shortid from 'shortid';
import {
  FdCard,
  FdTypography,
  FdCheckbox,
  FdIcons,
} from '@fifthdomain/fe-shared';

const useStyles = makeStyles((theme) => ({
  ksatChips: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      marginLeft: 0,
    },
  },
  previewChip: {
    backgroundColor: '#BDBDBD',
    '& > .MuiChip-deleteIcon': {
      color: '#000 !important',
    },
  },
}));

const ViewQuestion = ({ question, idx, graded }) => {
  const classes = useStyles();
  const { DragIndicator } = FdIcons;
  return (
    <FdCard
      variant="outlined"
      heading={`Question #${idx + 1}`}
      summary={
        graded && (
          <Box display="flex">
            <FdTypography variant="subtitle1">Points</FdTypography>
            <Box ml={1}>
              <FdTypography variant="body1">{question?.points}</FdTypography>
            </Box>
          </Box>
        )
      }
    >
      <Box>
        <FdTypography variant="subtitle1">{question?.question}</FdTypography>
      </Box>
      <Box mt={2}>
        {
          {
            'Free Text': graded && (
              <Box>
                <FdTypography variant="body1">
                  Type your answer into the box below.
                </FdTypography>
                <Box mt={2}>
                  <FdTypography variant="subtitle1">Answer:</FdTypography>
                </Box>
                <Box mt={2}>
                  <FdTypography variant="body1">
                    {question?.correctAnswer}
                  </FdTypography>
                </Box>
              </Box>
            ),
            'Multiple Choice': (
              <Box>
                {graded && (
                  <FdTypography variant="body1">
                    Use the checkboxes to select the correct answer(s). There is
                    more than one correct answer.
                  </FdTypography>
                )}
                <Box mt={2} display="flex" flexDirection="column">
                  {question?.multipleChoices
                    .sort((a, b) => a.order - b.order)
                    .map((o) => (
                      <FdCheckbox
                        id="allowMultipleAttempts"
                        label={o.answer}
                        key={shortid.generate()}
                        disabled
                      />
                    ))}
                </Box>
              </Box>
            ),
            'Single Choice': (
              <Box>
                {graded && (
                  <FdTypography variant="body1">
                    Select the correct answer below.
                  </FdTypography>
                )}
                <Box mt={2} mr={1}>
                  <RadioGroup name="radio-buttons-group">
                    {question?.multipleChoices
                      .sort((a, b) => a.order - b.order)
                      .map((ro) => (
                        <FormControlLabel
                          control={<Radio />}
                          value={ro.answer}
                          label={ro.answer}
                          key={shortid.generate()}
                          disabled
                        />
                      ))}
                  </RadioGroup>
                </Box>
              </Box>
            ),
            Ordering: (
              <Box>
                <FdTypography variant="body1">
                  Click and drag the answers below so they are in chronological
                  order from top to bottom.
                </FdTypography>
                <Box mt={2}>
                  {question?.multipleChoices
                    .sort((a, b) => a.order - b.order)
                    .map((op) => (
                      <Box
                        mb={2}
                        display="flex"
                        alignItems="center"
                        width="100%"
                        key={shortid.generate()}
                      >
                        <Box
                          mr={2}
                          style={{ color: '#000', cursor: 'pointer' }}
                        >
                          <DragIndicator />
                        </Box>
                        <Box mr={2} width="100%">
                          <FdTypography variant="body1">
                            {op.answer}
                          </FdTypography>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            ),
          }[question?.questionType]
        }
        <Box mt={2}>
          <Box className={classes.ksatChips}>
            {question.competencies.length > 0 &&
              question.competencies.map((c) => (
                <Chip label={c.competency} className={classes.previewChip} />
              ))}
          </Box>
        </Box>
      </Box>
    </FdCard>
  );
};

ViewQuestion.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  question: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  graded: PropTypes.bool,
};

ViewQuestion.defaultProps = {
  graded: true,
};

export default ViewQuestion;
