import Yup from '../Common';

export const initialValuesAttempts = {
  questions: [],
};

export const validationSchemaAttempts = {
  questions: Yup.array().of(
    Yup.object().shape({
      questionId: Yup.string(),
      questionType: Yup.string(),
      question: Yup.string(),
      correctAnswer: Yup.string().when(['questionType'], {
        is: (questionType) => questionType === 'Free Text',
        then: Yup.string().nullable(),
        otherwise: Yup.string().nullable(),
      }),
      points: Yup.number(),
      caseSensitive: Yup.bool().default(false).nullable(),
      whiteSpaceSensitive: Yup.bool().default(false).nullable(),
      order: Yup.number(),
      multipleChoices: Yup.array().when(['questionType'], {
        is: (questionType) => questionType !== 'Free Text',
        then: Yup.array().of(
          Yup.object().shape({
            choiceId: Yup.string(),
            answer: Yup.string()
              .required('Please state the option')
              .noWhitespace('Please state the option'),
            correctAnswer: Yup.bool().default(false).nullable(),
            order: Yup.number(),
          }),
        ),
        otherwise: Yup.array().of(
          Yup.object().shape({
            choiceId: Yup.string(),
            answer: Yup.string(),
            correctAnswer: Yup.bool(),
            order: Yup.number(),
          }),
        ),
      }),
    }),
  ),
};
