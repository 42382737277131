import React from 'react';
import { Box } from '@material-ui/core';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FdTypography,
  FdTab,
  FdBreadcrumbs,
  FdProgress,
  FdButton,
  FdIcons,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import {
  ViewDetails,
  ViewNetwork,
  ViewVirtualMachines,
} from '../components/LabsView';
import { getLabPrototype } from '../graphql/queries';
import {
  labInitialValues,
  labValidationSchema,
} from '../validation-schemas/Labs';

const ViewLab = () => {
  const { labId } = useParams();
  const { OpenInNew } = FdIcons;
  const editMode = window.location.pathname.includes('edit');

  const { getValues, reset } = useForm({
    defaultValues: labInitialValues,
    resolver: yupResolver(labValidationSchema),
    mode: 'all',
  });

  // Get Lab Prototype
  const { loading: getLabPrototypeLoading } = useQuery(gql(getLabPrototype), {
    variables: {
      id: labId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      reset({
        labName: data.getLabPrototype?.name,
        labDescription: data.getLabPrototype?.description,
        labNetworks: data.getLabPrototype?.networks?.items?.map((item) => item),
        labVms: data.getLabPrototype?.vms?.items?.map((item) => item),
      });
    },
  });

  const viewDetails = () => (
    <>
      <ViewDetails
        values={getValues()}
        heading={
          <Box display="flex" justifyContent="space-between">
            <FdTypography variant="h3">Details</FdTypography>
          </Box>
        }
        subHeading="Create a name for this lab. This name will be visible to all participants. 
                    Please also specify how long a participant has to complete the lab."
      />
    </>
  );

  const viewNetworks = () => (
    <ViewNetwork
      heading={
        <Box display="flex" justifyContent="flex-start">
          <FdTypography variant="h3">Networks</FdTypography>
          <Box p={0.8}>
            <FdTypography variant="body1" color="secondary">
              (optional)
            </FdTypography>
          </Box>
        </Box>
      }
      subHeading="Specify the network(s) that you’d like to add to this lab."
      values={getValues()}
    />
  );

  const viewVirtualMachines = () => (
    <ViewVirtualMachines
      heading={
        <Box display="flex" justifyContent="space-between">
          <FdTypography variant="h3">Virtual Machines</FdTypography>
        </Box>
      }
      subHeading="Add or remove virtual machines to your lab"
      values={getValues()}
    />
  );

  if (getLabPrototypeLoading) {
    return <FdProgress />;
  }
  return (
    <Box>
      <FdBreadcrumbs
        linkChain={[{ url: '/labs', name: 'Home' }]}
        currentPage={editMode ? 'Manage Labs / Edit' : 'Manage Labs / View'}
        linkComponent={RouterLink}
      />
      <Box display="flex" justifyContent="space-between">
        <FdTypography variant="h2">
          {`View ${getValues().labName}`}
        </FdTypography>
        <FdButton
          startIcon={<OpenInNew />}
          onClick={() => window.open(`/labs/test-lab/${labId}`)}
        >
          Enter Edit Lab Mode
        </FdButton>
      </Box>
      <FdDelayed>
        <FdTab
          label={[
            {
              label: 'Details',
              path: '/labs/',
              index: 0,
              data: () => viewDetails(),
            },
            {
              label: 'Networks',
              path: '/labs/',
              index: 1,
              data: () => viewNetworks(),
            },
            {
              label: 'Virtual Machines',
              path: '/labs/',
              index: 2,
              data: () => viewVirtualMachines(),
            },
          ]}
        />
      </FdDelayed>
    </Box>
  );
};
export default ViewLab;
