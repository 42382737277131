import React from 'react';
import PropTypes from 'prop-types';
import { FdModal } from '@fifthdomain/fe-shared';

const ChatSaveModal = ({ open, onCancel, onConfirm }) => (
  <FdModal
    title="Are you sure you want to leave ?"
    description="You have unsent message(s). If you leave now, your message(s) will be lost. Are you sure if you want to proceed?"
    confirm="Stay"
    dismiss="Leave and discard message"
    open={open}
    onConfirm={onCancel}
    onDismiss={onConfirm}
    data-cy="leave-modal"
  />
);

ChatSaveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ChatSaveModal;
