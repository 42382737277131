import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdTypography,
  FdCard,
  FdProgress,
  BasePage,
  FdButton,
  FdAlert,
  FdTable,
  FdChip,
  FdIcons,
  FdModal,
} from '@fifthdomain/fe-shared';
import { getLabPrototype } from '../graphql/queries';
import { startPrototype, stopPrototype } from '../graphql/mutations';
import { TEST_LAB_STATUS } from '../constants';

const ViewLab = () => {
  const { labId } = useParams();
  const history = useHistory();
  const { OpenInNew, Warning } = FdIcons;
  const [labStatus, setLabStatus] = useState('NOT_STARTED');
  const [exitTestMode, setExitTestMode] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const pollInterval = 2000;

  // Get Lab Prototype
  const {
    loading: getLabPrototypeLoading,
    data: labPrototypeData,
    startPolling,
    stopPolling,
  } = useQuery(gql(getLabPrototype), {
    variables: {
      id: labId,
    },
    onCompleted: (_data) => {
      const status =
        TEST_LAB_STATUS[_data?.getLabPrototype?.status] || 'NOT_DEFINED'; // lookup from a pre-defined list
      setLabStatus(status);
      // consider TESTING status as a changed state
      setIsDirty(status === TEST_LAB_STATUS.TESTING);
      // if status is either POWERING_OFF or READY then stop polling
      if ([TEST_LAB_STATUS.TESTING, TEST_LAB_STATUS.READY].includes(status)) {
        stopPolling();
      }
      // start polling if status is in transient state
      if (
        [TEST_LAB_STATUS.POWERING_ON, TEST_LAB_STATUS.POWERING_OFF].includes(
          status,
        )
      ) {
        startPolling(pollInterval);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [startPrototypeMutation] = useMutation(gql(startPrototype), {
    onCompleted: () => startPolling(pollInterval),
  });

  const [stopPrototypeMutation] = useMutation(gql(stopPrototype), {
    onCompleted: () => {
      setIsDirty(false);
      window.close();
    },
  });

  const onStartLab = () => {
    setLabStatus(TEST_LAB_STATUS.POWERING_ON);
    startPrototypeMutation({
      variables: { labPrototypeId: labPrototypeData?.getLabPrototype?.id },
    });
  };

  const onStopLab = () => {
    setLabStatus(TEST_LAB_STATUS.STOPPING);
    stopPrototypeMutation({
      variables: {
        labPrototypeId: labPrototypeData?.getLabPrototype?.id,
      },
    });
  };

  if (getLabPrototypeLoading && labStatus !== TEST_LAB_STATUS.POWERING_ON) {
    return <FdProgress />;
  }

  const { name: labName, description } =
    labPrototypeData?.getLabPrototype || {};

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Name',
    },
  ];

  const actions = [
    {
      label: 'Connect',
      show: () => true,
      onClick: (vm) => {
        window.open(
          `/labs/connect-test-lab/${vm?.labPrototypeId}/vdi/${vm?.id}`,
        );
      },
    },
  ];

  return (
    <BasePage
      heading="Test Lab"
      breadCrumbs={[{ url: '/labs', name: 'Home' }]}
      currentPageBreadcrumbLabel="Manage Labs / Test Lab"
      linkComponent={RouterLink}
      data-cy="test-lab-base-page"
    >
      <FdCard
        variant="outlined"
        heading={labName}
        summary={
          labStatus === TEST_LAB_STATUS.TESTING && (
            <Box display="flex" alignItems="center">
              <FdChip color="success" size="small" label="Edit MODE" />
              <Box ml={2}>
                <FdButton
                  startIcon={<OpenInNew />}
                  variant="secondary"
                  onClick={() => setExitTestMode(true)}
                >
                  Exit Edit Mode
                </FdButton>
              </Box>
            </Box>
          )
        }
      >
        <FdTypography variant="body1">{description}</FdTypography>
        {[TEST_LAB_STATUS.READY, TEST_LAB_STATUS.NOT_STARTED].includes(
          labStatus,
        ) && (
          <Box mt={2}>
            <FdButton size="large" onClick={onStartLab}>
              Start Lab
            </FdButton>
          </Box>
        )}
        {labStatus === TEST_LAB_STATUS.POWERING_ON && (
          <Box mt={2}>
            <FdAlert
              alertTitle="Your lab is being built. This may take several minutes."
              message="Constructing additional pylons"
              variant="info"
            />
            <Box my={1}>
              <FdProgress />
            </Box>
          </Box>
        )}
        {labStatus === TEST_LAB_STATUS.STOPPING && (
          <Box my={1}>
            <FdProgress />
          </Box>
        )}
      </FdCard>
      {labStatus === TEST_LAB_STATUS.TESTING && (
        <Box height="553px">
          <FdTable
            toolbarSettings={{
              title: 'Virtual Machines',
              filterButton: true,
              searchBox: true,
            }}
            rows={
              labPrototypeData?.getLabPrototype?.vms?.items.map((vm) => ({
                id: vm.id,
                name: vm.name,
                labPrototypeId: vm.labPrototypeId,
              })) || []
            }
            columns={columns}
            tablePageSize={5}
            actions={actions}
            gridId="labs-test-lab-vms-grid"
          />
        </Box>
      )}
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <Warning
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Exit Test Mode</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to exit test mode?
            </FdTypography>
            <Box mt={1}>
              This will delete your test lab. Any changes made to the lab will
              be saved.
            </Box>
          </Box>
        }
        confirm="Exit Test mode"
        dismiss="Cancel"
        open={exitTestMode}
        onConfirm={() => {
          setExitTestMode(false);
          onStopLab();
        }}
        onDismiss={() => {
          setExitTestMode(false);
        }}
      />
      <NavigationPrompt
        when={(currentLocation, nextLocation) =>
          (isDirty && currentLocation?.pathname && !nextLocation?.pathname) ||
          (isDirty && currentLocation?.pathname !== nextLocation?.pathname)
        }
        afterCancel={() => {
          if (window.location.pathname !== `/labs/lab-test/${labId}`) {
            history.goBack();
          }
        }}
        beforeConfirm={() => onStopLab()} // stop lab on exit
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="Your test lab will be deleted if you leave this page. Any changes you have made to the lab will be saved. "
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
            data-cy="leave-modal"
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};
export default ViewLab;
