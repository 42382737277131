import { Storage } from 'aws-amplify';

export const getImageUrlFromS3 = async (name) => {
  try {
    const image = await Storage.get(name);
    return image;
  } catch (error) {
    return error.message;
  }
};
