import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { gql, useMutation } from '@apollo/client';
import shortid from 'shortid';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdModal,
  FdProgress,
  FdButton,
  FdCard,
  FdIcons,
  FdTypography,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import {
  initialValuesQuestions,
  validationSchemaQuestions,
} from '../../../validation-schemas/Quizzes';
import { listQuestions } from '../../../graphql/queries';
import {
  createQuestionOption,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  updateQuestionOption,
  createCompetencyQuestion,
  deleteCompetencyQuestion,
  deleteQuestionOption,
} from '../../../graphql/mutations';
import {
  warningToastMessage,
  successToastMessage,
} from '../../../shared/utils/toast';
import ViewQuestion from './ViewQuestion';
import AddQuizQuestions from '../AddQuizQuestions';
import { QUESTION_MAPPING } from '../../../constants';
import { getKeyByValue } from '../../../shared/utils/objectUtils';
import { formatMinutes } from '../../../shared/utils/dateUtils';
import { createUpdateQuestions } from '../quizUtils';

const ViewQuizQuestions = ({ quizData, onModeChange }) => {
  const [viewMode, setViewMode] = useState(true);
  const [validateOnSaveOnly, setValidateOnSaveOnly] = useState(false);
  const history = useHistory();
  const { AvTimer } = FdIcons;
  const validationSchema = Yup.object().shape({
    ...validationSchemaQuestions(quizData?.graded),
  });

  const reactHookFormMethods = useForm({
    defaultValues: initialValuesQuestions,
    resolver: yupResolver(validationSchema),
    context: { validateOnSaveOnly },
    mode: 'all',
  });
  const {
    control,
    reset,
    trigger,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = reactHookFormMethods;

  const { fields: questions } = useFieldArray({
    control,
    name: 'questions',
  });

  const quizId = quizData?.id;
  const totalPoints = quizData?.questions?.items.reduce(
    (acc, cv) => acc + cv.point,
    0,
  );

  // setValidateOnSaveOnly to false if there are errors corrected temporarily
  // this to avoid errors reported in upcoming entries
  useEffect(() => {
    if (!errors?.questions?.multipleChoices) {
      setValidateOnSaveOnly(false);
    }
  }, [errors]);

  // Get Quiz questions & options
  const { loading: quizQuestionsDataLoading, refetch } = useQueryRecursive(
    gql(listQuestions),
    {
      variables: {
        filter: { quizId: { eq: quizId } },
      },
      onCompleted: async (_data) => {
        const { items: questionsApiData } = _data?.listQuestions;
        reset({
          questions: questionsApiData
            ?.sort((a, b) => a.orderNumber - b.orderNumber)
            .map((q, qIdx) => ({
              questionId: q.id,
              questionType: getKeyByValue(QUESTION_MAPPING, q.type),
              question: q.name,
              correctAnswer: q.answer,
              points: q.point,
              multipleChoices: q.options?.items.map((o) => ({
                choiceId: o.id,
                answer: o.optionName,
                correctAnswer: o.correctAnswer,
                order: o.orderNumber,
              })),
              competencies: q.competencies?.items?.map((c) => ({
                cDbId: c.id,
                competencyId: c.competencyId,
                specialtyArea: c.area?.areaName,
                competency: c.competency?.description,
              })),
              caseSensitive: q.caseSensitive,
              whiteSpaceSensitive: q.whiteSpaceSensitive,
              order: q.orderNumber || qIdx + 1,
            })),
        });
      },
      skip: !quizId,
    },
  );

  const [createQuestionOptionMutation] = useMutation(gql(createQuestionOption));
  const [createQuestionMutation] = useMutation(gql(createQuestion));
  const [updateQuestionMutation] = useMutation(gql(updateQuestion));
  const [deleteQuestionMutation] = useMutation(gql(deleteQuestion));
  const [updateQuestionOptionMutation] = useMutation(gql(updateQuestionOption));
  const [deleteQuestionOptionMutation] = useMutation(gql(deleteQuestionOption));
  const [createCompetencyQuestionsMutation] = useMutation(
    gql(createCompetencyQuestion),
  );
  const [deleteCompetencyQuestionsMutation] = useMutation(
    gql(deleteCompetencyQuestion),
  );

  if (quizQuestionsDataLoading) return <FdProgress />;

  const ActionButtons = () => (
    <Box display="flex">
      {!viewMode ? (
        <Box display="flex">
          <FdButton
            variant="secondary"
            onMouseDown={() => {
              reset();
              setViewMode(true);
              onModeChange(true);
              warningToastMessage('Quiz not saved');
            }}
            data-cy="cancel-button"
          >
            Cancel
          </FdButton>
          <Box ml={1}>
            <FdButton
              onMouseDown={async () => {
                setValidateOnSaveOnly(true);
                // setValue takes a while to re-render hence setTimeout
                setTimeout(async () => {
                  if (await trigger()) {
                    // edit quiz questions & options
                    await createUpdateQuestions({
                      createQuestionMutation,
                      updateQuestionMutation,
                      deleteQuestionMutation,
                      createQuestionOptionMutation,
                      updateQuestionOptionMutation,
                      deleteQuestionOptionMutation,
                      createCompetencyQuestionsMutation,
                      deleteCompetencyQuestionsMutation,
                      values: getValues(),
                      quizId,
                      setValue,
                    });
                    successToastMessage('Success! Quiz updated');
                    setValidateOnSaveOnly(false);
                    setViewMode(true);
                  }
                }, 1000);
                setTimeout(async () => {
                  if (await trigger()) {
                    refetch();
                  }
                }, 2000);
              }}
              data-cy="save-button"
            >
              Save
            </FdButton>
          </Box>
        </Box>
      ) : (
        <FdButton
          onClick={() => {
            setViewMode(false);
            onModeChange(false);
          }}
          data-cy="edit-button"
        >
          Edit
        </FdButton>
      )}
    </Box>
  );

  return (
    <Box>
      <FormProvider {...reactHookFormMethods}>
        <form>
          <FdCard
            variant="outlined"
            heading="Quiz Questions"
            data-cy="quiz-questions"
            summary={
              <Box display="flex" alignItems="center">
                <Box mr={2} display="flex">
                  <Box display="flex" mr={2}>
                    <AvTimer />
                    <Box ml={1}>
                      <FdTypography variant="subtitle1">
                        {formatMinutes(quizData?.duration)}
                      </FdTypography>
                    </Box>
                  </Box>
                  {quizData?.graded && (
                    <Box display="flex">
                      <FdTypography variant="subtitle1">
                        Total Points:
                      </FdTypography>
                      <Box ml={1}>
                        <FdTypography variant="subtitle1">
                          {totalPoints}
                        </FdTypography>
                      </Box>
                    </Box>
                  )}
                </Box>
                {ActionButtons ? <ActionButtons /> : []}
              </Box>
            }
          >
            {viewMode ? (
              questions.map((q, idx) => (
                <ViewQuestion
                  question={q}
                  idx={idx}
                  key={shortid.generate()}
                  graded={quizData?.graded}
                />
              ))
            ) : (
              <AddQuizQuestions userGraded={quizData?.graded} />
            )}
          </FdCard>
        </form>
      </FormProvider>
      <NavigationPrompt
        when={(currentLocation, nextLocation) =>
          (isDirty && currentLocation?.pathname && !nextLocation?.pathname) ||
          (isDirty && currentLocation?.pathname !== nextLocation?.pathname)
        }
        afterCancel={() => {
          if (
            window.location.pathname !==
            `/labs/courses/view/${quizId}?tabindex=1`
          ) {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
            data-cy="leave-modal"
          />
        )}
      </NavigationPrompt>
    </Box>
  );
};

ViewQuizQuestions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  quizData: PropTypes.object.isRequired,
  onModeChange: PropTypes.func.isRequired,
};

export default ViewQuizQuestions;
