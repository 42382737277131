import { Storage } from 'aws-amplify';
import shortid from 'shortid';
import { amplifyConfig } from '@fifthdomain/fe-shared';

const getContentType = (fileType) => {
  switch (fileType) {
    case '.md':
      return 'text/markdown';
    case '.tar.gz':
      return 'application/gzip';
    case 'application/x-yaml':
      return 'application/yaml';
    default:
      return 'application/gzip';
  }
};

const fileUploadAWS = async ({ file, fileType }, setUploadPercentage) => {
  const fileName = file.name.slice(0, file.name.indexOf('.'));
  const fileExtension = file.name.slice(file.name.indexOf('.') + 1);
  const res = await Storage.put(
    `${fileName}${shortid.generate()}.${fileExtension}`,
    file,
    {
      contentType: getContentType(fileType),
      progressCallback(progress) {
        const percentage =
          Math.round((progress.loaded / progress.total) * 100) / 100;
        setUploadPercentage?.(percentage);
      },
    },
  );
  return {
    name: res.key,
    type: file.type,
    size: file.size,
    url: URL.createObjectURL(file),
    bucket: amplifyConfig?.aws_user_files_s3_bucket,
    region: amplifyConfig?.aws_user_files_s3_bucket_region,
  };
};

export default fileUploadAWS;
