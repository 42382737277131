import fileUploadAWS from '../../../shared/utils/fileUploadAWS';

export const createModules = async ({
  updateCourseModuleMutation,
  createCourseModuleMutation,
  values,
  courseId,
  setValue,
  updateModulePartMutation,
  createModulePartMutation,
  allQuizzes,
  allLabs,
  courseUserIds,
  createModulePartProgressMutation,
}) => {
  const { modules: courseModules } = values;
  // create/edit modules
  await Promise.all(
    courseModules?.map(async (m, idx) => {
      const actionMutation = m.moduleId
        ? updateCourseModuleMutation
        : createCourseModuleMutation;
      const actionId = m.moduleId ? { id: m.moduleId } : undefined;
      await actionMutation({
        variables: {
          input: {
            courseId,
            description: m.moduleDescription,
            duration: m.moduleDuration,
            name: m.moduleName,
            orderNumber: m.order,
            ...actionId,
          },
        },
        onCompleted: async (_moduleData) => {
          const actionModule = m.moduleId
            ? 'updateCourseModule'
            : 'createCourseModule';
          const mId = _moduleData?.[actionModule]?.id;
          if (!m.moduleId) {
            setValue(`modules[${idx}].moduleId`, mId);
          }
          // create/edit parts
          await Promise.all(
            m?.parts?.map(async (p, pIdx) => {
              const partActionMutation = p.partId
                ? updateModulePartMutation
                : createModulePartMutation;
              const pActionId = p.partId ? { id: p.partId } : undefined;
              // upload file if any
              let fileUploadInfo;
              // if file present and its new
              if (['PDF', 'Markdown'].includes(p.partType) && !p.file?.key) {
                fileUploadInfo = await fileUploadAWS({
                  file: p.file,
                  fileType: p.type,
                });
              }
              const isLab = p.partType === 'Lab';
              await partActionMutation({
                variables: {
                  input: {
                    courseModuleId: mId,
                    description: p.partDescription,
                    name: p.partTitle,
                    orderNumber: p.order,
                    quizId:
                      p.partType === 'Quiz'
                        ? allQuizzes.find((q) => q.name === p.quiz)?.id
                        : undefined,
                    url: p?.partType === 'Video' ? p?.video : undefined,
                    type: String(p.partType).toUpperCase(),
                    file: ['PDF', 'Markdown'].includes(p.partType)
                      ? {
                          bucket: fileUploadInfo?.bucket || p.file.bucket,
                          key: fileUploadInfo?.name || p.file.key,
                          region: fileUploadInfo?.region || p.file.region,
                        }
                      : undefined,
                    labId: isLab
                      ? allLabs.filter((l) => l.name === p.lab)[0]?.id
                      : undefined,
                    duration: isLab ? p.labDuration : undefined,
                    deletionMode: isLab ? p.deletionMode : undefined,
                    expiry: isLab ? p.labExpiryDateTime : undefined,
                    inactivityExpiry: isLab ? p.labAbandonment : undefined,
                    initialLabCount: isLab ? p.initialLabPool : undefined,
                    minLabCount: isLab ? p.minimumLabPool : undefined,
                    ...pActionId,
                  },
                },
                onCompleted: async (_partData) => {
                  const actionPart = m.partId
                    ? 'updateModulePart'
                    : 'createModulePart';
                  const pId = _partData?.[actionPart]?.id;

                  if (!p.partId) {
                    setValue(`modules[${idx}].parts[${pIdx}].partId`, pId);
                    if (courseUserIds?.length > 0) {
                      await Promise.all(
                        courseUserIds?.map(async (courseUserId) => {
                          await Promise.all(
                            await createModulePartProgressMutation({
                              variables: {
                                input: {
                                  courseUserId,
                                  modulePartId: pId,
                                  status: 'NOT_STARTED',
                                },
                              },
                            }),
                          );
                        }),
                      );
                    }
                  }
                },
              });
            }),
          );
        },
      });
    }),
  );
};

export const addModulePartsProgress = async ({
  createModulePartProgressMutation,
  modulePartIds,
  courseUserId,
}) => {
  await Promise.all(
    modulePartIds?.map(async (modulePartId) => {
      await createModulePartProgressMutation({
        variables: {
          input: {
            courseUserId,
            modulePartId,
            status: 'NOT_STARTED',
          },
        },
      });
    }),
  );
};

export const deleteModulesParts = async ({
  deleteModulePartMutation,
  updateModulePartMutation,
  deleteCourseModuleMutation,
  values,
}) => {
  const { modulesToDelete, partsToDelete } = values;

  const partsToDeleteNonLab = partsToDelete?.filter(
    (item) => item?.partType !== 'Lab',
  );
  // delete parts except Lab Type
  partsToDeleteNonLab?.map(async (p) => {
    await Promise.all(
      await deleteModulePartMutation({
        variables: {
          input: {
            id: p?.partId,
          },
        },
      }),
    );
  });
  const partsToUpdateLab = partsToDelete?.filter(
    (item) => item?.partType === 'Lab',
  );
  partsToUpdateLab?.map(async (p) => {
    await Promise.all(
      await updateModulePartMutation({
        variables: {
          input: {
            id: p?.partId,
            status: 'DELETE_REQUESTED',
          },
        },
      }),
    );
  });

  // delete modules
  modulesToDelete?.map(async (m) => {
    await Promise.all(
      await deleteCourseModuleMutation({
        variables: {
          input: {
            id: m,
          },
        },
      }),
    );
  });
};

export const getCourseProgressStatus = ({
  partsProgress,
  courseModulePartIds,
}) => {
  // only consider data with a valid modulePart
  const partsFinished =
    partsProgress?.filter((p) => p.status === 'FINISHED').length || 0;
  let status = 'Not Started';
  const courseProgress = Math.round(
    (partsFinished / courseModulePartIds?.length) * 100,
  );

  if (courseProgress > 0 && courseProgress < 100) {
    status = 'In Progress';
  }
  if (courseProgress >= 100) {
    status = 'Completed';
  }
  return { status, courseProgress };
};

export const getCourseProgress = ({ modulePartData, courseData }) => {
  // only consider data with a valid modulePart
  const modulePartDataFiltered = modulePartData?.filter(
    (p) => p?.modulePart && p?.status === 'FINISHED',
  );
  const coursePartData = courseData
    ?.map((courseDataItem) => courseDataItem?.parts?.items)
    .flat();
  // Course Progress
  const individualCourseProgress =
    coursePartData?.length > 0
      ? Math.round(
          (modulePartDataFiltered?.length / coursePartData?.length) * 100,
        )
      : 0;
  return {
    individualCourseProgress,
  };
};

export const getUnreadMessageCount = (_messages, _userId) => {
  const messages = _messages?.filter((m) => m.sender.id === _userId) || [];
  return messages.length;
};
