import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdAccordion,
  FdTypography,
  FdButton,
  FdTextField,
  FdSelect,
  FdAlert,
  FdCheckbox,
} from '@fifthdomain/fe-shared';
import { useFieldArray } from 'react-hook-form';
import LabNetworkVms from './LabNetworkVms';
import FdBoxButton from '../FdBoxButton';
import { VM_NETWORK_OPTIONS } from '../../constants';

const LabVirtualMachines = ({
  Controller,
  control,
  heading,
  labNetworkValues,
  getValueVM,
  getValueLabVM,
  setValueVM,
  VAppTemplates,
  preHeader,
  triggerAfterSubmit,
  getValueDeleteVMNetwork,
  isEditMode,
  labStatus,
}) => {
  const {
    fields: labVmsFields,
    remove: labVmsRemove,
    append: labVmsAppend,
  } = useFieldArray({
    control,
    name: 'labVms',
  });

  // eslint-disable-next-line no-unused-vars
  const { customConfigurations, ...vmNetworkOptionsRest } = VM_NETWORK_OPTIONS;

  const networkOptions = getValueLabVM?.map((object1) =>
    Object.keys(object1).filter((key) => object1[key] === true),
  );

  // Vm Network Values
  const vmNetworkValues = getValueLabVM
    ?.map((vMNetwork) => vMNetwork?.networks?.items?.map((item) => item))
    .flat();

  return (
    <FdCard
      variant="outlined"
      heading={heading}
      subHeading="Add or remove virtual machines to your lab."
      preHeader={
        preHeader?.message && (
          <Box mb={2}>
            <FdAlert
              alertTitle="At least one VM must be added"
              variant="error"
              message="Please add a VM"
            />
          </Box>
        )
      }
    >
      {labVmsFields.map((item, index) => {
        return (
          <Box my={2} key={item.id}>
            <FdAccordion
              open
              startAdornment
              showToggleButton={false}
              summary={() => (
                <Box display="flex" width="100%">
                  <Box sx={{ flexGrow: 1 }}>
                    <FdTypography variant="h3">Virtual Machine</FdTypography>
                  </Box>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      labVmsRemove(index);
                      setValueVM(
                        'labDeleteVMs',
                        getValueVM.concat(
                          getValueLabVM?.filter(
                            (labNetw) => labNetw.name === item.name,
                          ),
                        ),
                      );
                    }}
                  >
                    DELETE
                  </FdButton>
                </Box>
              )}
              content={() => (
                <Box display="flex" flexDirection="column">
                  {isEditMode &&
                  labStatus === 'READY' &&
                  // check if vm has been transferred to another org
                  item?.template &&
                  !VAppTemplates?.some(
                    (_vAppTemplate) => _vAppTemplate.template === item.template,
                  ) ? (
                    <>
                      <Box mt={2} mb={2}>
                        <FdTypography variant="subtitle1">VM Name</FdTypography>
                        <FdTypography variant="body1" color="secondary">
                          {item?.name}
                        </FdTypography>
                      </Box>
                      <Box mt={2} mb={2}>
                        <FdTypography variant="subtitle1">
                          VM Image
                        </FdTypography>
                        <FdTypography variant="body1" color="secondary">
                          {item?.template}
                        </FdTypography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Controller
                        control={control}
                        name={`labVms[${index}].name`}
                        render={({
                          field: { ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <Box mt={1} mb={2}>
                            <FdTextField
                              id={`labVms[${index}].name`}
                              label="VM Name"
                              required
                              width="406px"
                              error={error}
                              helperText={error && error.message}
                              {...rest}
                              inputRef={ref}
                            />
                          </Box>
                        )}
                      />
                      <Box mt={0} mb={3}>
                        <Controller
                          control={control}
                          name={`labVms[${index}].template`}
                          render={({
                            field: { ref, ...rest },
                            fieldState: { error },
                          }) => (
                            <Box mt={1} mb={2}>
                              <FdSelect
                                inputTitle="VM Image"
                                id={`labVms[${index}].template`}
                                placeholder="Select one option"
                                defaultSelected={
                                  VAppTemplates?.find(
                                    (vAppTemplate) =>
                                      vAppTemplate.template === rest.value,
                                  )?.label || rest.value
                                }
                                error={error}
                                helperText={error && error.message}
                                options={VAppTemplates?.map(
                                  (vapptemplate) => vapptemplate.label,
                                )}
                                width="406px"
                                {...rest}
                                inputRef={ref}
                              />
                            </Box>
                          )}
                        />
                      </Box>
                    </>
                  )}
                  <Box mt={0} mb={3}>
                    <Controller
                      control={control}
                      name={`labVms[${index}].networkOptions`}
                      render={({ field: { ref, ...rest } }) => (
                        <Box mt={1} mb={2}>
                          <FdCheckbox
                            name={`labVms[${index}].networkOptions`}
                            options={
                              rest?.value?.includes('captureVmUserEvents')
                                ? vmNetworkOptionsRest
                                : VM_NETWORK_OPTIONS
                            }
                            multiple
                            defaultSelected={
                              rest.value || networkOptions[index]
                            }
                            formLegend="Network Options"
                            {...rest}
                            inputRef={ref}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                  <LabNetworkVms
                    Controller={Controller}
                    control={control}
                    nextIndex={index}
                    labNetworkValues={labNetworkValues}
                    getValueDeleteVMNetwork={getValueDeleteVMNetwork}
                    setValueVMNetwork={setValueVM}
                    vmNetworkValues={vmNetworkValues}
                  />
                </Box>
              )}
            />
          </Box>
        );
      })}
      <FdBoxButton
        onClick={async (e) => {
          e.preventDefault();
          labVmsAppend({});
          if (preHeader?.message) {
            await triggerAfterSubmit('labVms');
          }
        }}
      >
        ADD VIRTUAL MACHINE
      </FdBoxButton>
    </FdCard>
  );
};

LabVirtualMachines.propTypes = {
  Controller: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labNetworkValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      cidr: PropTypes.string,
    }),
  ).isRequired,
  preHeader: PropTypes.shape({
    message: PropTypes.string,
  }),
  VAppTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  triggerAfterSubmit: PropTypes.func,
  setValueVM: PropTypes.func,
  getValueVM: PropTypes.arrayOf(
    PropTypes.shape({
      catalog: PropTypes.string,
      cores: PropTypes.string,
      createdAt: PropTypes.string,
      id: PropTypes.string,
      labPrototypeId: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  getValueLabVM: PropTypes.arrayOf(PropTypes.object),
  getValueVMNetwork: PropTypes.arrayOf(
    PropTypes.shape({
      adapterType: PropTypes.string,
      id: PropTypes.string,
      labPrototypeVMId: PropTypes.string,
      mac: PropTypes.string,
      ip: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  getValueDeleteVMNetwork: PropTypes.arrayOf(PropTypes.object),
  isEditMode: PropTypes.bool,
  labStatus: PropTypes.string.isRequired,
};

LabVirtualMachines.defaultProps = {
  preHeader: {},
  heading: undefined,
  triggerAfterSubmit: () => {},
  setValueVM: () => {},
  getValueVM: [],
  // setValueVMNetwork: () => {},
  getValueVMNetwork: [],
  getValueDeleteVMNetwork: [],
  getValueLabVM: [],
  isEditMode: false,
};

export default LabVirtualMachines;
