import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import * as Yup from 'yup';
import Countdown from 'react-countdown';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { differenceInMilliseconds, addMinutes } from 'date-fns';
import _ from 'lodash';
import {
  FdCard,
  FdButton,
  FdIcons,
  FdTypography,
  FdModal,
  FdProgress,
  FdTable,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import {
  listModulePartProgressesByCourseUserId,
  listQuizAttemptsByCourseUserId,
} from '../../graphql/queries';
import {
  getQuizForParticipant,
  getCourseUser,
} from '../../queries/customQueries';
import { formatMinutes } from '../../shared/utils/dateUtils';
import { updateCourseUserStatus } from '../../shared/utils/updateCourseUser';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import {
  initialValuesAttempts,
  validationSchemaAttempts,
} from '../../validation-schemas/Quizzes/attempt';
import AttemptQuestion from '../Quiz/Attempt/AttemptQuestion';
import { QUESTION_MAPPING } from '../../constants';
import { getKeyByValue } from '../../shared/utils/objectUtils';
import {
  updateModulePartProgress,
  attemptQuiz,
  createModulePartProgress,
  updateCourseUser,
} from '../../graphql/mutations';

const QuizAttempt = ({ part, quiz, courseId, userId }) => {
  const [showStartModal, setShowStartModal] = useState(false);
  const history = useHistory();
  const urlPath = window.location.pathname;
  const { courseUserId, partId } = useParams();
  const { Refresh, AvTimer, Warning, ErrorOutline } = FdIcons;

  const validationSchema = Yup.object().shape(validationSchemaAttempts);

  const reactHookFormMethods = useForm({
    defaultValues: initialValuesAttempts,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { control, reset, getValues } = reactHookFormMethods;

  const { fields: questions } = useFieldArray({
    control,
    name: 'questions',
  });

  const [
    createModulePartProgressMutation,
    { loading: createModulePartProgressLoading },
  ] = useMutation(gql(createModulePartProgress));

  const {
    data: modulePartProgress,
    loading: modulePartProgressLoading,
    refetch: refetchModulePartProgress,
  } = useQueryRecursive(gql(listModulePartProgressesByCourseUserId), {
    variables: {
      courseUserId,
      // filter: {
      //   modulePartId: { eq: partId },
      // },
    },

    onCompleted: async (_progressData) => {
      const isProgressData =
        _progressData?.listModulePartProgressesByCourseUserId?.items[0];
      // create default NOT_STARTED data if not present
      if (!isProgressData) {
        await createModulePartProgressMutation({
          variables: {
            input: {
              courseUserId,
              modulePartId: partId,
              status: 'NOT_STARTED',
              userId,
              courseId,
            },
          },
          onCompleted: () => {
            refetchModulePartProgress();
          },
        });
      }
    },
  });

  const {
    data: quizAttemptsData,
    loading: quizAttemptsLoading,
    refetch: refetchQuizAttempts,
  } = useQueryRecursive(gql(listQuizAttemptsByCourseUserId), {
    variables: {
      courseUserId,
      filter: {
        quizId: { eq: quiz?.id },
      },
    },
  });

  const {
    data: courseUserData,
    loading: courseUserDataLoading,
    refetch: refetchCourseUser,
  } = useQuery(gql(getCourseUser), {
    variables: {
      userCourseId: courseUserId,
    },
    skip: !courseUserId,
  });

  // Get Quiz details
  const { loading: quizDataLoading } = useQuery(gql(getQuizForParticipant), {
    variables: {
      id: quiz?.id,
    },
    onCompleted: (_data) => {
      const questionsApiData = _data?.getQuiz?.questions?.items || [];
      const shuffleOptions = (input, actual) => {
        let shuffled = _.shuffle(input);
        const sOrder = shuffled.map((s) => s.orderNumber);
        while (_.isEqual(sOrder, actual)) {
          shuffled = shuffleOptions(shuffled, actual);
        }
        return shuffled;
      };
      reset({
        questions: questionsApiData?.map((q) => {
          const randomize =
            q.type === 'ORDERING'
              ? shuffleOptions(
                  q.options.items,
                  q.options.items.map((qi) => qi.orderNumber),
                )
              : q.options?.items;

          return {
            questionId: q.id,
            questionType: getKeyByValue(QUESTION_MAPPING, q.type),
            question: q.name,
            correctAnswer: null,
            points: q.point,
            multipleChoices: randomize.map((o, oIdx) => ({
              choiceId: o.id,
              answer: o.optionName,
              correctAnswer: null,
              order: q.type === 'ORDERING' ? oIdx + 1 : o.orderNumber,
            })),
          };
        }),
      });
    },
    skip: !quiz?.id,
  });

  const [
    updateModulePartProgressMutation,
    { loading: updateModulePartProgressLoading },
  ] = useMutation(gql(updateModulePartProgress));

  const [updateCourseUserMutation] = useMutation(gql(updateCourseUser), {
    onCompleted: (_data) => {
      refetchCourseUser();
    },
  });

  const [attemptQuizMutation, { loading: attemptQuizLoading }] = useMutation(
    gql(attemptQuiz),
    {
      onCompleted: () => {
        reset();
        refetchModulePartProgress();
        refetchQuizAttempts();
        successToastMessage('Success! Quiz submitted');
      },
    },
  );
  if (
    quizDataLoading ||
    updateModulePartProgressLoading ||
    modulePartProgressLoading ||
    attemptQuizLoading ||
    quizAttemptsLoading ||
    createModulePartProgressLoading ||
    courseUserDataLoading
  ) {
    return <FdProgress />;
  }

  const progressData =
    modulePartProgress?.listModulePartProgressesByCourseUserId?.items?.find(
      (modulePartProgressItem) =>
        modulePartProgressItem.modulePartId === partId,
    );

  const quizStatus = progressData?.status || 'NOT_STARTED';
  const showStartQuizButton =
    quizStatus === 'NOT_STARTED' ||
    (quiz?.multipleAttempts && quizStatus === 'FINISHED');
  const startButtonText =
    showStartQuizButton && quizStatus === 'NOT_STARTED'
      ? 'Start Quiz'
      : 'Re-attempt Quiz';

  const quizAttempts =
    quizAttemptsData?.listQuizAttemptsByCourseUserId?.items || [];

  const groupByAttempts = _.values(_.groupBy(quizAttempts, 'attempt'));

  const resultsRows = groupByAttempts.reverse().map((a, aIdx) => ({
    id: aIdx,
    questionsAttempted: `${
      a.filter(
        (qa) =>
          (qa.answer && qa.answer !== '') ||
          (qa.answers && qa.answers?.length > 0),
      ).length || 0
    }/${a.length}`,
    correctAnswers: a.filter((qa) => qa.success).length || 0,
    points: a
      .filter((qa) => qa.success)
      .reduce(
        (ac, v) =>
          ac +
            questions.find((qns) => qns.questionId === v.questionId)?.points ||
          0,
        0,
      ),
  }));

  const timeLimit = quiz?.duration
    ? `Time Limit: ${formatMinutes(quiz?.duration)}`
    : 'No Time Limit';
  const attemptsText = quiz?.multipleAttempts
    ? 'Re-attempts allowed'
    : 'Re-attempts not allowed';

  const endDateTime = addMinutes(
    new Date(progressData?.startedOn),
    quiz?.duration || 0,
  );

  const milliSecondsToFinish = differenceInMilliseconds(
    new Date(endDateTime),
    new Date(),
  );

  const onFinishQuiz = () => {
    const { questions: quizQuestions } = getValues();
    updateModulePartProgressMutation({
      variables: {
        input: {
          id: progressData?.id,
          status: 'FINISHED',
          finishedOn: new Date().toISOString(),
          userId,
          courseId,
        },
      },
      onCompleted: () => {
        updateCourseUserStatus(
          modulePartProgress,
          updateCourseUserMutation,
          courseUserData,
          partId,
        );
        attemptQuizMutation({
          variables: {
            courseUserId,
            quizId: quiz?.id,
            questionAnswers: quizQuestions.map((q) => {
              switch (q.questionType) {
                case 'Free Text':
                  return {
                    questionId: q.questionId,
                    answer: q.correctAnswer || '',
                  };
                case 'Single Choice':
                  return {
                    questionId: q.questionId,
                    answer:
                      q.multipleChoices.find((c) => c.correctAnswer)
                        ?.choiceId || null,
                  };
                case 'Multiple Choice':
                  return {
                    questionId: q.questionId,
                    answers:
                      q.multipleChoices
                        .filter((c) => c.correctAnswer)
                        .map((o) => o.choiceId) || null,
                  };
                case 'Ordering':
                  return {
                    questionId: q.questionId,
                    orderingQuestionAnswers: q.multipleChoices.map((oc) => ({
                      questionOptionId: oc.choiceId,
                      orderNumber: oc.order,
                    })),
                  };
                default:
                  return {};
              }
            }),
          },
        });
      },
    });
  };
  const isDirty = quizStatus === 'STARTED';
  const startQuiz = () =>
    progressData
      ? updateModulePartProgressMutation({
          variables: {
            input: {
              id: progressData?.id,
              status: 'STARTED',
              startedOn: new Date().toISOString(),
              userId,
              courseId,
            },
          },
          onCompleted: () => refetchModulePartProgress(),
        })
      : createModulePartProgressMutation({
          variables: {
            input: {
              courseUserId,
              modulePartId: partId,
              status: 'STARTED',
              startedOn: new Date().toISOString(),
              userId,
              courseId,
            },
          },
          onCompleted: () => refetchModulePartProgress(),
        });
  return (
    <Box>
      <Box>
        <FdCard
          variant="outlined"
          heading={part?.name}
          summary={
            <Box display="flex" alignItems="center">
              {quizStatus === 'STARTED' && quiz?.duration ? (
                <Box display="flex" ml={2}>
                  <AvTimer />
                  <Box ml={1}>
                    <FdTypography variant="subtitle1">
                      <Box display="flex">
                        Time Remaining:
                        <Box ml={1}>
                          <Countdown
                            date={
                              milliSecondsToFinish > 0
                                ? Date.now() + milliSecondsToFinish
                                : Date.now() + 1000
                            }
                            onComplete={onFinishQuiz}
                            daysInHours
                          />
                        </Box>
                      </Box>
                    </FdTypography>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Box display="flex">
                    {quiz?.multipleAttempts ? <Refresh /> : <ErrorOutline />}
                    <Box ml={1}>
                      <FdTypography variant="subtitle1">
                        {attemptsText}
                      </FdTypography>
                    </Box>
                  </Box>
                  {quiz?.duration > 0 && (
                    <Box display="flex" ml={2}>
                      <AvTimer />
                      <Box ml={1}>
                        <FdTypography variant="subtitle1">
                          {timeLimit}
                        </FdTypography>
                      </Box>
                    </Box>
                  )}
                  <Box ml={2}>
                    {showStartQuizButton && (
                      <FdButton
                        style={{ width: '170px' }}
                        onClick={() =>
                          quiz?.duration > 0
                            ? setShowStartModal(true)
                            : startQuiz()
                        }
                      >
                        {startButtonText}
                      </FdButton>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          }
        >
          <Box mt={2}>
            <FdTypography variant="body1">{part?.description}</FdTypography>
          </Box>
          {quizStatus === 'FINISHED' && quiz?.showResults && (
            <Box height="625px" mt={2}>
              <FdTable
                toolbarSettings={{
                  title: 'Results',
                  filterButton: false,
                  searchBox: false,
                }}
                rows={resultsRows}
                columns={
                  quiz?.graded
                    ? [
                        {
                          field: 'questionsAttempted',
                          flex: 1,
                          headerName: 'Questions Attempted',
                        },
                        {
                          field: 'correctAnswers',
                          flex: 1,
                          headerName: 'Correct Answers',
                        },
                        {
                          field: 'points',
                          flex: 1,
                          headerName: 'Points Scored',
                        },
                      ]
                    : [
                        {
                          field: 'questionsAttempted',
                          flex: 1,
                          headerName: 'Questions Attempted',
                        },
                      ]
                }
                tablePageSize={10}
                actions={[]}
                gridId="labs-quiz-attempts"
              />
            </Box>
          )}
          {quizStatus === 'STARTED' && (
            <Box mt={2}>
              <FormProvider {...reactHookFormMethods}>
                <form>
                  <DndProvider backend={HTML5Backend}>
                    {questions.map((q, idx) => (
                      <AttemptQuestion
                        question={q}
                        idx={idx}
                        key={q.id}
                        graded={quiz?.graded}
                      />
                    ))}
                  </DndProvider>
                  <Box>
                    <FdButton onClick={onFinishQuiz}>Submit</FdButton>
                  </Box>
                </form>
              </FormProvider>
            </Box>
          )}
        </FdCard>
        <FdModal
          size="sm"
          title={
            <Box display="flex" alignItems="center">
              <Warning
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Start Quiz?</span>
            </Box>
          }
          description="Are you sure you want to start the quiz?"
          confirm="Start QUIZ"
          dismiss="CANCEL"
          open={showStartModal}
          onConfirm={() => {
            setShowStartModal(false);
            startQuiz();
          }}
          onDismiss={() => {
            setShowStartModal(false);
            warningToastMessage('Quiz not started');
          }}
        >
          <Box mt={2}>
            <FdTypography variant="body1" color="secondary">
              The quiz time will start to count down if you start the quiz.
            </FdTypography>
          </Box>
        </FdModal>
      </Box>
      <NavigationPrompt
        when={(currentLocation, nextLocation) =>
          (isDirty && currentLocation?.pathname && !nextLocation?.pathname) ||
          (isDirty && currentLocation?.pathname !== nextLocation?.pathname)
        }
        afterCancel={() => {
          if (window.location.pathname !== urlPath) {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the quiz and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={() => {
              warningToastMessage('Quiz not submitted');
              onConfirm();
            }}
            data-cy="leave-modal"
          />
        )}
      </NavigationPrompt>
    </Box>
  );
};

QuizAttempt.propTypes = {
  part: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  quiz: PropTypes.objectOf(
    PropTypes.shape({
      duration: PropTypes.string,
      multipleAttempts: PropTypes.bool,
      description: PropTypes.string,
    }),
  ).isRequired,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default QuizAttempt;
