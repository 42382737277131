import React from 'react';
import { Grid, makeStyles, Card, CardContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import SummaryTitle from './SummaryTitle';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
  },
  itemCard: {
    '&:not(:last-child)': {
      borderRight: '1px solid #E0E0E0',
    },
  },
  card: {
    width: '100%',
    paddingTop: '4px',
    boxSizing: 'border-box',
  },
}));

const Summary = ({
  data,
  titleVariant,
  subtitleVariant,
  justifyContent,
  divider,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} data-cy="summary-card" variant="outlined">
      <CardContent>
        <Grid container className={classes.root}>
          {data.map((d) => (
            <Grid
              item
              xs
              className={divider ? classes.itemCard : ''}
              key={d.description + shortid.generate()}
            >
              <Grid container justifyContent="center">
                <SummaryTitle
                  data={d}
                  titleVariant={titleVariant}
                  subtitleVariant={subtitleVariant}
                  justifyContent={justifyContent}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

Summary.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
    }).isRequired,
  ).isRequired,
  titleVariant: PropTypes.string,
  subtitleVariant: PropTypes.string,
  justifyContent: PropTypes.string,
  divider: PropTypes.bool,
};

Summary.defaultProps = {
  titleVariant: 'h3',
  subtitleVariant: 'subtitle1',
  justifyContent: 'center',
  divider: true,
};

export default Summary;
