import Yup from '../Common';

export const courseInitialValues = {
  courseId: '',
  name: '',
  description: '',
  category: '',
  availability: false,
  modules: [],
  modulesToDelete: [],
  partsToDelete: [],
  partsSummary: [],
};

export const courseValidationSchema = {
  courseId: Yup.string(),
  name: Yup.string()
    .required('Please enter a Course Name')
    .max(200, 'Course Name must be 200 characters or less')
    .noWhitespace('Please enter a valid Course Name'),
  description: Yup.string()
    .required('Please enter a description')
    .noWhitespace('Please enter a valid description'),
  category: Yup.string().nullable(),
  availability: Yup.bool(),
  modules: Yup.array()
    .of(
      Yup.object().shape({
        moduleId: Yup.string(),
        moduleName: Yup.string()
          .required('Please enter a Module Name')
          .max(120, 'Module Name must be 120 characters or less')
          .noWhitespace('Please enter a valid Module Name'),
        moduleDescription: Yup.string()
          .required('Please enter a module description')
          .max(256, 'Module description must be 256 characters or less')
          .noWhitespace('Please enter a valid module description'),
        moduleDuration: Yup.number()
          .required('Estimated time to learn field can’t be empty')
          .typeError('Estimated time to learn field can’t be empty')
          .min(1, 'Module duration should be more than 0')
          .test(
            'is-decimal',
            'Please enter a positive, whole number',
            (value) => !`${value}`.match(/^\d*\.{1}\d*$/), // Check for decimal only
          ),
        order: Yup.number().nullable(),
        forDelete: Yup.bool().default(false).nullable(),
        accordionState: Yup.bool().default(false).nullable(),
        parts: Yup.array()
          .of(
            Yup.object().shape({
              partId: Yup.string(),
              partType: Yup.string().required('Please select a part type'),
              partTitle: Yup.string()
                .required('Please enter a Part title')
                .max(120, 'Part title must be 120 characters or less')
                .noWhitespace('Please enter a Part title'),
              partDescription: Yup.string()
                .required('Please enter a part description')
                .max(256, 'Part description must be 256 characters or less')
                .noWhitespace('Please enter a part description'),
              order: Yup.number().nullable(),
              forDelete: Yup.bool().default(false).nullable(),
              accordionState: Yup.bool().default(false).nullable(),
              lab: Yup.string().when(['partType'], {
                is: (partType) => partType === 'Lab',
                then: Yup.string().required('Please select a lab'),
                otherwise: Yup.string().nullable(),
              }),
              quiz: Yup.string().when(['partType'], {
                is: (partType) => partType === 'Quiz',
                then: Yup.string().required('Please select a quiz'),
                otherwise: Yup.string().nullable(),
              }),
              video: Yup.string().when(['partType'], {
                is: (partType) => partType === 'Video',
                then: Yup.string()
                  .required('Video URL field is empty')
                  .max(1500, 'Embed URL must be less than 1500 characters')
                  .noWhitespace('Video URL field is empty'),
                otherwise: Yup.string().nullable(),
              }),
              file: Yup.mixed().when(['partType'], {
                is: (partType) => ['PDF', 'Markdown'].includes(partType),
                then: Yup.mixed().required(),
                otherwise: Yup.mixed().nullable(),
              }),
            }),
          )
          .min(1, 'Please add a part'),
      }),
    )
    .min(1, 'Please add a module'),
  partsSummary: Yup.array().of(
    Yup.object()
      .shape({
        moduleId: Yup.string(),
        parts: Yup.array().of(
          Yup.object().shape({
            partType: Yup.string().nullable(),
            quiz: Yup.string().nullable(),
            labDuration: Yup.number().nullable(),
          }),
        ),
      })
      .nullable(),
  ),
  modulesToDelete: Yup.array().of(Yup.string()),
  partsToDelete: Yup.array().of(
    Yup.object().shape({
      partId: Yup.string(),
      partType: Yup.string(),
    }),
  ),
};
