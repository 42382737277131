import { isInSubnet } from 'is-in-subnet';
import IPCIDR from 'ip-cidr';
import Yup from '../Common';

export const labInitialValues = {
  labName: '',
  labDescription: '',
  labNetworks: [],
  labVms: [],
  labDeleteNetworks: [],
  labDeleteVMs: [],
  labDeleteVMNetworks: [],
};

const ipRegex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

const validSubnetCheck = (value, labNetworks) => {
  // NetworkCidr value
  const networkCidrValue = labNetworks?.from[3]?.value?.labNetworks?.find(
    (labNetwork) => labNetwork?.name === labNetworks?.parent?.name,
  )?.cidr;

  // Check for Valid Ip Address
  const inValidValue = value?.match(ipRegex);

  // Check IP Address  within the CIDR subnet range
  const validRange =
    inValidValue?.input &&
    networkCidrValue &&
    isInSubnet(inValidValue?.input, networkCidrValue);

  // Check IP Address cannot be the first or last in the range.First and last is used at the backend
  const ipCidrRange =
    networkCidrValue && new IPCIDR(networkCidrValue)?.toRange();
  const usedIpAddress = ipCidrRange?.includes(value);

  return { validRange, networkCidrValue, usedIpAddress, inValidValue };
};

const validCidrCheck = (value) => {
  const cidr = value.trim().split('/');
  const ip = cidr[0];
  const subnet = Number(cidr[1]);

  const inValidCidr =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/.test(
      value,
    );
  const validSubnet = subnet > 31 || subnet < 1;
  const reservedIp = ip.startsWith('127');
  const specialIp = ip.startsWith('10.144') || ip.startsWith('172.16');

  // Calculate Total hosts
  let totalHost = 0;
  for (let i = 0, j = 31; i < subnet; i += 1) {
    totalHost += 2 ** j;
    j -= 1;
  }

  // Convert IP to decimal
  const ipDec = ip.split('.').reduce((x, y) => 256 * x + Number(y), 0);

  // Check if the first host IP address is within the subnet range
  const validFirstIp = (ipDec & ~totalHost) !== 0; // eslint-disable-line no-bitwise

  return { inValidCidr, validSubnet, reservedIp, specialIp, validFirstIp };
};

export const labValidationSchema = Yup.object().shape({
  labName: Yup.string()
    .required('Please enter a Lab Name')
    .max(200, 'Lab Name must be 200 characters or less')
    .noWhitespace('Please enter a Lab Name'),
  labDescription: Yup.string()
    .max(250, 'Lab Description must be 250 characters or less')
    .test(
      'noWhitespaceNonRequired',
      'Please enter a Lab Description',
      (value) => !value || (value && value.trim().length),
    ),
  labNetworks: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Please enter a Network Name')
          .noWhitespace('Please enter a Network Name')
          .max(200, 'Network Name must be 200 characters or less'),
        cidr: Yup.string()
          .required('Please enter a valid CIDR (###.###.###.###/##)')
          .test('ip-invalid-netmask', function (value) {
            const { path, createError } = this;
            if (value) {
              const {
                inValidCidr,
                validSubnet,
                reservedIp,
                specialIp,
                validFirstIp,
              } = validCidrCheck(value);
              if (!inValidCidr)
                return createError({
                  path,
                  message: 'Please enter a valid CIDR (###.###.###.###/##)',
                });
              if (validSubnet)
                return createError({
                  path,
                  message: 'Invalid CIDR Length',
                });
              if (reservedIp)
                return createError({
                  path,
                  message: 'This IP is reserved for loopback addresses',
                });
              if (specialIp)
                return createError({
                  path,
                  message: 'This IP is reserved for special purposes',
                });
              if (!validFirstIp)
                return createError({
                  path,
                  message: 'Invalid IP address',
                });
              return true;
            }
            return true;
          }),
      }),
    )
    .unique('name', 'Please enter a unique name for this network'),
  labVms: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Please enter a VM Name')
          .noWhitespace('Please enter a VM Name')
          .max(200, 'VM Name must be 200 characters or less'),
        template: Yup.string().required('Please select a VM image'),
        networks: Yup.object().shape({
          items: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string()
                  .required('Please select a network')
                  .test(
                    'correct-network',
                    'Please select a network',
                    (value, labNetworks) =>
                      labNetworks?.from[3]?.value?.labNetworks
                        ?.map((labNetwork) => labNetwork.name)
                        ?.includes(value),
                  ),
                ip: Yup.string()
                  .nullable()
                  .test('ip-invalid-netmask', function (value, labNetworks) {
                    const { path, createError } = this;
                    if (value) {
                      const {
                        validRange,
                        networkCidrValue,
                        usedIpAddress,
                        inValidValue,
                      } = validSubnetCheck(value, labNetworks);
                      if (!inValidValue)
                        return createError({
                          path,
                          message:
                            'Please enter a valid IP address (###.###.###.###)',
                        });
                      if (!validRange)
                        return createError({
                          path,
                          message: `The IP Address must be in the subnet range specified by the CIDR [${networkCidrValue}]`,
                        });
                      if (usedIpAddress)
                        return createError({
                          path,
                          message:
                            'This IP Address is already in use. Please enter a different IP Address',
                        });
                      return true;
                    }
                    return true;
                  }),
                mac: Yup.string()
                  .nullable()
                  .test(
                    'mac-invalid',
                    'Please enter a valid MAC Address (##-##-##-##-##-##, ##:##:##:##:##:## or ####.####.####)',
                    (value) =>
                      value
                        ? value?.match(
                            '^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$',
                          )
                        : true,
                  ),
              }),
            )
            .unique('name', 'Please select a network which is not in use')
            .unique('ip', 'Please enter a unique IP Address')
            .unique('mac', 'Please enter a unique MAC Address'),
        }),
      }),
    )
    .unique('name', 'Please enter a unique VM Name')
    .unique('ip', 'Please enter a unique IP Address')
    .unique('mac', 'Please enter a unique MAC Address')
    .min(1),
});

export const duplicateLabValidationSchema = Yup.object().shape({
  labDuplicate: Yup.string()
    .required('Please enter a Lab Name')
    .max(200, 'Lab Name must be 200 characters or less')
    .noWhitespace('Please enter a  Lab Name'),
});
