import React from 'react';
import { Link, Box } from '@material-ui/core';
import {
  FdTypography,
  FdIcons,
  CREATE_TICKET_COMPETITIONS_URL,
  HELPDESK_EMAIL,
} from '@fifthdomain/fe-shared';

const Error = () => {
  const { WarningTwoTone } = FdIcons;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box mt={20}>
        <WarningTwoTone
          style={{
            fontSize: 68,
            color: 'rgb(255 142 55)',
          }}
        />
      </Box>
      <Box m={3}>
        <FdTypography variant="h3">Ooops! Something went wrong.</FdTypography>
      </Box>
      <Box m={3}>
        <FdTypography variant="body1">
          {'Please raise a '}
          <Link
            href={CREATE_TICKET_COMPETITIONS_URL}
            target="_blank"
            rel="noreferrer"
          >
            {'support ticket '}
          </Link>
          or contact us at
          <Link href={`mailto:${HELPDESK_EMAIL}`}>{` ${HELPDESK_EMAIL}.`}</Link>
        </FdTypography>
      </Box>
    </Box>
  );
};

export default Error;
