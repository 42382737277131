import React, { useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  AuthContext,
  FdProgress,
  Authorization,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import { getUserById, listProductsByOrgId } from '../graphql/queries';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);
  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
    skip: !user,
  });
  const { data: orgProductsData, loading: orgProductsLoading } = useQuery(
    gql(listProductsByOrgId),
    {
      variables: {
        orgId: userData?.getUserById?.items?.[0].orgId,
      },
      skip: !userData,
    },
  );

  const orgProducts = orgProductsData?.listProductsByOrgId?.items?.map(
    (orgProduct) => orgProduct?.product?.name,
  );

  const managerPermissions = Authorization.canManageContent(
    globalSnap?.permissions,
  );

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authUser) => {
        setUser(authUser);
      })
      .catch(() => {
        singleSpa.navigateToUrl('/');
      });
  }, []);

  if (userLoading || orgProductsLoading) return <FdProgress />;

  return (
    <Route
      {...rest}
      render={(props) => {
        // all other valid auth routes
        if (user && userData) {
          if (managerPermissions) {
            // check if the org has access to Labs
            if (
              Array.isArray(orgProducts) &&
              !orgProducts?.includes('Labs') &&
              /\/labs\/courses\/?/.test(window.location.href)
            ) {
              singleSpa.navigateToUrl('/landing');
            }
            return <Component {...rest} {...props} />;
          }
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AuthRoute;
