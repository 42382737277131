import React from 'react';
import PropTypes from 'prop-types';
import { ViewDetails, ViewNetwork, ViewVirtualMachines } from '../LabsView';

const LabSummary = ({ values }) => (
  <>
    <ViewDetails values={values} heading="Details" />
    <ViewNetwork values={values} heading="Networks" />
    <ViewVirtualMachines values={values} heading="Virtual Machines" />
  </>
);

LabSummary.propTypes = {
  // eslint-disable-next-line react/require-default-props
  values: PropTypes.object,
};

export default LabSummary;
