import React from 'react';
import PropTypes from 'prop-types';
import { FdIcons } from '@fifthdomain/fe-shared';

const { InsertDriveFile, OndemandVideo, Subject, Extension } = FdIcons;

const PartIcon = ({ type }) =>
  ({
    PDF: <InsertDriveFile />,
    MARKDOWN: <Subject />,
    LAB: <OndemandVideo />,
    VIDEO: <OndemandVideo />,
    QUIZ: <Extension />,
  }[type] || null);

PartIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default PartIcon;
