import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { Box, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import shortid from 'shortid';
import {
  FdCard,
  FdTypography,
  FdCheckbox,
  FdTextField,
} from '@fifthdomain/fe-shared';
import OrderingAnswer from './OrderingAnswer';

const AttemptQuestion = ({ question, idx, graded }) => {
  const { control, setValue } = useFormContext();
  const { fields: multipleChoices, swap: swapChoice } = useFieldArray({
    control,
    name: `questions[${idx}].multipleChoices`,
  });

  return (
    <FdCard
      variant="outlined"
      heading={`Question #${idx + 1}`}
      summary={
        graded && (
          <Box display="flex">
            <FdTypography variant="subtitle1">Points</FdTypography>
            <Box ml={1}>
              <FdTypography variant="body1">{question?.points}</FdTypography>
            </Box>
          </Box>
        )
      }
    >
      <Box>
        <FdTypography variant="subtitle1">{question?.question}</FdTypography>
      </Box>
      <Box mt={2}>
        {
          {
            'Free Text': (
              <Box>
                <FdTypography variant="body1">
                  Write your answer in the textbox below.
                </FdTypography>
                <Box mt={2}>
                  <Controller
                    control={control}
                    name={`questions[${idx}].correctAnswer`}
                    render={({
                      field: { value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <Box mt={1} mb={2}>
                        <FdTextField
                          id={`questions[${idx}].correctAnswer`}
                          label=""
                          value={value}
                          required
                          fullWidth
                          placeholder="Write your answer here."
                          error={error}
                          helperText={error && error.message}
                          data-cy="name"
                          multiline
                          rows={3}
                          {...rest}
                          inputRef={ref}
                        />
                      </Box>
                    )}
                  />
                </Box>
              </Box>
            ),
            'Multiple Choice': (
              <Box>
                <FdTypography variant="body1">
                  Use the checkboxes to select the correct answer(s). You can
                  select more than one answer.
                </FdTypography>
                <Box mt={2} display="flex" flexDirection="column">
                  {multipleChoices
                    .sort((a, b) => a.order - b.order)
                    .map((o, oIdx) => (
                      <Controller
                        control={control}
                        name={`questions[${idx}].multipleChoices[${oIdx}].correctAnswer`}
                        render={({
                          field: { ref, value: fieldValue, ...rest },
                        }) => (
                          <Box>
                            <FdCheckbox
                              id={`questions[${idx}].multipleChoices[${oIdx}].correctAnswer`}
                              label={o.answer}
                              key={shortid.generate()}
                              checked={fieldValue}
                              value={fieldValue?.value}
                              data-cy={`questions[${idx}].multipleChoices[${oIdx}].correctAnswer`}
                              {...rest}
                              inputRef={ref}
                            />
                          </Box>
                        )}
                      />
                    ))}
                </Box>
              </Box>
            ),
            'Single Choice': (
              <Box>
                <FdTypography variant="body1">
                  Select the correct answer below.
                </FdTypography>
                <Box mt={2} mr={1}>
                  <RadioGroup
                    name="radio-buttons-group"
                    onChange={(e) => {
                      const selectedValue = e.target.defaultValue;
                      question?.multipleChoices?.forEach((mc, mcIdx) => {
                        const checkValue = selectedValue === mc.answer;
                        setValue(
                          `questions[${idx}].multipleChoices[${mcIdx}].correctAnswer`,
                          checkValue,
                        );
                      });
                    }}
                  >
                    {multipleChoices
                      .sort((a, b) => a.order - b.order)
                      .map((ro) => (
                        <FormControlLabel
                          control={<Radio />}
                          value={ro.answer}
                          label={ro.answer}
                          key={ro.id}
                        />
                      ))}
                  </RadioGroup>
                </Box>
              </Box>
            ),
            Ordering: (
              <Box>
                <FdTypography variant="body1">
                  Click and drag the answers below so they are in chronological
                  order from top to bottom.
                </FdTypography>
                <Box mt={2}>
                  {multipleChoices
                    .sort((a, b) => Number(a.order) - Number(b.order))
                    .map((op, opIdx) => (
                      <OrderingAnswer
                        key={op.id}
                        qIdx={idx}
                        cIdx={opIdx}
                        swapChoice={swapChoice}
                      />
                    ))}
                </Box>
              </Box>
            ),
          }[question?.questionType]
        }
      </Box>
    </FdCard>
  );
};

AttemptQuestion.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  question: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  graded: PropTypes.bool,
};

AttemptQuestion.defaultProps = {
  graded: true,
};

export default AttemptQuestion;
