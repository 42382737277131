/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateLabInstance = /* GraphQL */ `
  subscription OnUpdateLabInstance {
    onUpdateLabInstance {
      labPrototypeId
      modulePartId
    }
  }
`;
