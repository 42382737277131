import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdTextField, FdCheckbox } from '@fifthdomain/fe-shared';
import FdTextView from '../../FdTextView';

const CourseDetails = ({ heading, isEdit }) => {
  const { control, getValues } = useFormContext();

  return (
    <Box>
      <FdCard heading={heading} data-cy="details" variant="outlined">
        <Box display="flex" flexDirection="column">
          <Controller
            control={control}
            name="name"
            render={({
              field: { value, ref, ...rest },
              fieldState: { error },
            }) => (
              <Box mt={1} mb={2}>
                {isEdit ? (
                  <FdTextField
                    id="name"
                    label="Course Name"
                    value={value}
                    required
                    fullWidth
                    placeholder="Course name must be 200 characters or less"
                    error={error}
                    helperText={error && error.message}
                    data-cy="name"
                    {...rest}
                    inputRef={ref}
                  />
                ) : (
                  <FdTextView label="Name" value={value} />
                )}
              </Box>
            )}
          />
          <Box mt={0} mb={0}>
            <Controller
              control={control}
              name="description"
              render={({
                field: { value, ref, ...rest },
                fieldState: { error },
              }) => (
                <Box mb={2}>
                  {isEdit ? (
                    <FdTextField
                      id="description"
                      label="Course Description"
                      value={value}
                      fullWidth
                      multiline
                      required
                      rows={3}
                      placeholder="Enter a course description here"
                      error={error}
                      helperText={error && error.message}
                      data-cy="description"
                      {...rest}
                      inputRef={ref}
                    />
                  ) : (
                    <FdTextView label="Description" value={value} />
                  )}
                </Box>
              )}
            />
          </Box>
        </Box>
      </FdCard>
      {getValues('status') !== 'DRAFT' && (
        <FdCard data-cy="category" variant="outlined">
          <Box display="flex" flexDirection="column">
            <Controller
              control={control}
              name="availability"
              render={({
                field: { ref, value: fieldValue, ...rest },
                fieldState: { error },
              }) => (
                <Box>
                  <FdCheckbox
                    id="availability"
                    label="Make the course available to users"
                    disabled={!isEdit}
                    checked={fieldValue}
                    formLegend="Availability"
                    value={fieldValue?.value}
                    error={error && error.message}
                    data-cy="availability"
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
          </Box>
        </FdCard>
      )}
    </Box>
  );
};

CourseDetails.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isEdit: PropTypes.bool,
};

CourseDetails.defaultProps = {
  heading: 'Details',
  isEdit: false,
};

export default CourseDetails;
