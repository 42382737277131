import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAsyncEffect from 'use-async-effect';
import { Box } from '@material-ui/core';
import {
  FdProgress,
  FdCard,
  FdButton,
  FdMarkdownRender,
  FdAlert,
  FdChip,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { gql, useMutation, useQuery } from '@apollo/client';
import { getImageUrlFromS3 } from '../../shared/utils/getImageFromS3';
import {
  createModulePartProgress,
  updateCourseUser,
  updateModulePartProgress,
} from '../../graphql/mutations';
import { listModulePartProgressesByCourseUserId } from '../../graphql/queries';
import { getCourseUser } from '../../queries/customQueries';
import { updateCourseUserStatus } from '../../shared/utils/updateCourseUser';

function MarkdownPage({ file, name, description, courseId, userId }) {
  const [markdown, setMarkdown] = useState('');
  const [markdownLoading, setMarkdownLoading] = useState(true);
  const { courseUserId, partId } = useParams();

  const {
    data: modulePartProgress,
    loading: modulePartProgressLoading,
    refetch: refetchModulePartProgress,
  } = useQueryRecursive(gql(listModulePartProgressesByCourseUserId), {
    variables: {
      courseUserId,
    },
  });

  const {
    data: courseUserData,
    loading: courseUserDataLoading,
    refetch: refetchCourseUser,
  } = useQuery(gql(getCourseUser), {
    variables: {
      userCourseId: courseUserId,
    },
    skip: !courseUserId,
  });

  const [
    updateModulePartProgressMutation,
    { loading: updateModulePartProgressLoading },
  ] = useMutation(gql(updateModulePartProgress), {
    onCompleted: (_data) => {
      refetchModulePartProgress();
    },
  });

  const [updateCourseUserMutation] = useMutation(gql(updateCourseUser), {
    onCompleted: (_data) => {
      refetchCourseUser();
    },
  });

  const [
    createModulePartProgressMutation,
    { loading: createModulePartProgressLoading },
  ] = useMutation(gql(createModulePartProgress), {
    onCompleted: (_data) => {
      refetchModulePartProgress();
    },
  });

  useAsyncEffect(async () => {
    const fileUrlKey = String(file?.key) || '';
    const fileUrlS3 = await getImageUrlFromS3(fileUrlKey);
    const markdownData = await (await fetch(fileUrlS3)).text();
    setMarkdown(markdownData);
    setMarkdownLoading(false);
  });

  if (markdownLoading || modulePartProgressLoading || courseUserDataLoading)
    return <FdProgress />;

  const currentModulePartProgress =
    modulePartProgress?.listModulePartProgressesByCourseUserId?.items?.find(
      (modulePartProgressItem) =>
        modulePartProgressItem.modulePartId === partId,
    );

  const isMarkdownCompleted = currentModulePartProgress?.status === 'FINISHED';

  return (
    <Box>
      <FdCard
        variant="outlined"
        heading={name}
        summary={
          <Box display="flex">
            {isMarkdownCompleted ? (
              <FdChip color="success" size="small" label="completed" />
            ) : (
              <FdButton
                onClick={() => {
                  const now = new Date().toISOString();

                  if (!currentModulePartProgress) {
                    createModulePartProgressMutation({
                      variables: {
                        input: {
                          courseUserId,
                          modulePartId: partId,
                          status: 'FINISHED',
                          finishedOn: new Date().toISOString(),
                          userId,
                          courseId,
                        },
                      },
                    });
                    return;
                  }

                  updateModulePartProgressMutation({
                    variables: {
                      input: {
                        id: currentModulePartProgress?.id,
                        status: 'FINISHED',
                        finishedOn: now,
                        userId,
                        courseId,
                      },
                    },
                  });
                  updateCourseUserStatus(
                    modulePartProgress,
                    updateCourseUserMutation,
                    courseUserData,
                    partId,
                  );
                }}
              >
                {updateModulePartProgressLoading ||
                createModulePartProgressLoading
                  ? 'Loading...'
                  : 'Mark Complete'}
              </FdButton>
            )}
          </Box>
        }
        subHeading={description}
      />
      {isMarkdownCompleted && (
        <Box mb={2}>
          <FdAlert
            alertTitle="This part has been marked as complete!"
            variant="success"
          />
        </Box>
      )}
      {markdown && (
        <FdCard variant="outlined">
          <FdMarkdownRender markdown={markdown} />
        </FdCard>
      )}
    </Box>
  );
}

MarkdownPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

MarkdownPage.defaultProps = {
  description: '',
};

export default MarkdownPage;
