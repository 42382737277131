import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import singleSpa from 'single-spa';
import { FdTable } from '@fifthdomain/fe-shared';

const InsightTable = ({ rows, modulePart, courseUserId }) => {
  const columns =
    modulePart === 'Labs'
      ? [
          { field: 'name', width: 250, headerName: ' Lab Name' },

          {
            field: 'started',
            width: 250,
            headerName: 'Started',
            renderType: 'booleanIcon',
          },
          {
            field: 'completed',
            width: 250,
            headerName: 'Completed',
            renderType: 'booleanIcon',
          },
          { field: 'labTime', width: 250, headerName: 'Lab Time Used (mins)' },
        ]
      : modulePart === 'Quizzes'
      ? [
          { field: 'name', width: 250, headerName: 'Quiz Name' },

          {
            field: 'attempted',
            width: 250,
            headerName: 'Attempted',
            renderType: 'booleanIcon',
          },
          {
            field: 'attempts',
            width: 250,
            headerName: 'Attempts',
          },
          { field: 'topScore', width: 250, headerName: 'Top Score' },
          { field: 'recentScore', width: 250, headerName: 'Most Recent Score' },
          { field: 'successRate', width: 250, headerName: 'Success Rate (%)' },
        ]
      : [
          {
            field: 'name',
            width: 250,
            headerName: modulePart === 'PDFs' ? 'PDF Name' : 'Markdown Name',
          },

          {
            field: 'completed',
            width: 250,
            headerName: 'Completed',
            renderType: 'booleanIcon',
          },
        ];
  const actions = [
    {
      label: 'View',
      show: () => true,
      onClick: ({ id, type }) => {
        switch (type) {
          case 'QUIZ':
            return singleSpa.navigateToUrl(`/labs/quizzes/view/${id}`);
          case 'LAB':
            return window.open(`/labs/view-course-lab/${id}/${courseUserId}`);
          default:
            return '';
        }
      },
    },
  ];

  return (
    <Box height="415px">
      <FdTable
        toolbarSettings={{
          title: modulePart,
        }}
        rows={rows}
        columns={columns}
        actions={['Labs', 'Quizzes'].includes(modulePart) && actions}
        gridId="labs-insights-table"
      />
    </Box>
  );
};

InsightTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  modulePart: PropTypes.string.isRequired,
  courseUserId: PropTypes.string,
};

InsightTable.defaultProps = {
  courseUserId: undefined,
};

export default InsightTable;
