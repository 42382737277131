import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const FdTextView = ({ label, value }) => {
  return (
    <Box>
      <FdTypography variant="subtitle1">{label}</FdTypography>
      <FdTypography variant="body1" color="secondary">
        {value}
      </FdTypography>
    </Box>
  );
};

FdTextView.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

FdTextView.defaultProps = {
  label: '',
  value: '',
};

export default FdTextView;
