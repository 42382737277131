import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FdTypography, FdTextField, FdAlert } from '@fifthdomain/fe-shared';
import ChoiceCard from './ChoiceCard';
import FdBoxButton from '../FdBoxButton';
import QuestionPoints from './QuestionPoints';

const MultipleChoiceQuestion = ({ qIdx, userGraded }) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const {
    fields: multipleChoices,
    append: appendChoice,
    remove: removeChoice,
    swap: swapChoice,
  } = useFieldArray({
    control,
    name: `questions[${qIdx}].multipleChoices`,
  });
  const optionError = errors?.questions?.[qIdx]?.multipleChoices?.message;
  const competencyError = errors?.questions?.[qIdx]?.competencies?.message;

  return (
    <Box width="100%">
      <FdTypography variant="body1">
        {
          {
            'Multiple Choice':
              'Use the action icons to select the correct answer(s).',
            'Single Choice':
              'Use the action icons to select the correct answer.',
            Ordering:
              'Place the options below in the correct order, from top to bottom.',
          }[getValues(`questions[${qIdx}].questionType`)]
        }
      </FdTypography>
      {optionError && (
        <Box mt={2} mb={2}>
          <FdAlert variant="error" message={optionError} />
        </Box>
      )}
      {competencyError && (
        <Box mt={2} mb={2}>
          <FdAlert variant="error" message={competencyError} />
        </Box>
      )}
      <Box mt={2}>
        <Controller
          control={control}
          name={`questions[${qIdx}].question`}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id={`questions[${qIdx}].question`}
                label="Question"
                fullWidth
                required
                placeholder="Type your quiz question here"
                error={error}
                helperText={error && error.message}
                data-cy={`questions[${qIdx}].question`}
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
      </Box>
      <DndProvider backend={HTML5Backend}>
        <Box mt={3}>
          <FdTypography fontWeight="bold" variant="body1">
            {getValues(`questions[${qIdx}].questionType`) === 'Multiple Choice'
              ? 'Multiple Choice Options'
              : getValues(`questions[${qIdx}].questionType`) === 'Ordering'
              ? 'Click and drag the options below so they are in chronological order from top to bottom.'
              : 'Single Choice Options'}
          </FdTypography>
          <Box mt={2}>
            {multipleChoices
              .sort((a, b) => a.order - b.order)
              .map((choice, cIdx) => (
                <ChoiceCard
                  key={choice.id}
                  qIdx={qIdx}
                  cIdx={cIdx}
                  deleteChoice={removeChoice}
                  swapChoice={swapChoice}
                  userGraded={userGraded}
                />
              ))}
          </Box>
          <FdBoxButton
            onClick={(e) => {
              e.preventDefault();
              appendChoice({
                order: multipleChoices.length + 1,
              });
            }}
          >
            ADD OPTION
          </FdBoxButton>
        </Box>
      </DndProvider>
      <QuestionPoints qIdx={qIdx} userGraded={userGraded} />
    </Box>
  );
};

MultipleChoiceQuestion.propTypes = {
  qIdx: PropTypes.number.isRequired,
  isEdit: PropTypes.bool,
  userGraded: PropTypes.bool,
};

MultipleChoiceQuestion.defaultProps = {
  isEdit: false,
  userGraded: true,
};

export default MultipleChoiceQuestion;
