export const getUserStatus = (status) => {
  switch (status) {
    case 'registered':
      return 'success';
    case 'invited':
      return 'default';
    default:
      return 'default';
  }
};
