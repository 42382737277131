import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTable, FdChip } from '@fifthdomain/fe-shared';
import { getLabVmsStatusColor } from '../../shared/utils/getStatusColor';
import { upperCaseFirstLetter } from '../../shared/utils/stringUtils';

function LabVmsTable({ rows, actions, headerActions, ...rest }) {
  const columns = [
    { field: 'name', width: 400, headerName: 'Name' },
    {
      field: 'status',
      width: 400,
      headerName: 'Status',
      valueGetter: (params) => params?.value,
      renderCell: (params) => (
        <FdChip
          color={getLabVmsStatusColor(params?.row?.status)}
          size="small"
          label={upperCaseFirstLetter(params?.row?.status)}
        />
      ),
      sortComparator: (v1, v2, param1, param2) =>
        param1.value.localeCompare(param2.value),
    },
  ];

  return (
    <Box height="715px" bgcolor="#fff">
      <FdTable
        toolbarSettings={{
          title: 'Virtual Machines',
          headerActions,
          filterButton: true,
          searchBox: true,
        }}
        rows={rows}
        columns={columns}
        tablePageSize={10}
        actions={actions}
        gridId="lab-vms-table"
        {...rest}
      />
    </Box>
  );
}

LabVmsTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

LabVmsTable.defaultProps = {
  actions: [],
  headerActions: [],
};

export default LabVmsTable;
