import _ from 'lodash';

export const getLabPerformance = (
  courseModulePartProgress,
  modulePartData,
  totalPoolUsers,
  coursePartData,
) => {
  // Lab Calculation
  const individualLabs = modulePartData?.filter(
    (modulePart) =>
      modulePart?.type === 'LAB' &&
      (modulePart.status === 'FINISHED' || modulePart.status === 'STARTED'),
  ).length;
  const totalLabs = coursePartData?.filter(
    (coursePart) => coursePart.type === 'LAB',
  ).length;
  const individualLabCompletionRate =
    totalLabs > 0 ? Math.round((individualLabs / totalLabs) * 100) : 0;

  const userPoolLabs = courseModulePartProgress?.filter(
    (modulePart) =>
      modulePart?.modulePart?.type === 'LAB' &&
      (modulePart.status === 'FINISHED' || modulePart.status === 'STARTED'),
  ).length;
  const userPoolCompletion = totalLabs > 0 ? userPoolLabs / totalLabs : 0;
  const userPoolLabCompletionRate =
    totalPoolUsers > 0
      ? Math.round((userPoolCompletion / totalPoolUsers) * 100)
      : 0;

  return {
    individualLabCompletionRate,
    userPoolLabCompletionRate,
  };
};

export const getQuizPerformance = (
  courseModulePartProgress,
  modulePartData,
  totalPoolUsers,
  coursePartData,
) => {
  // Quiz Calculation
  const individualQuiz = modulePartData?.filter(
    (modulePart) =>
      modulePart?.type === 'QUIZ' &&
      (modulePart.status === 'FINISHED' || modulePart.status === 'STARTED'),
  ).length;
  const totalQuiz = coursePartData?.filter(
    (coursePart) => coursePart.type === 'QUIZ',
  ).length;
  const individualQuizCompletionRate =
    totalQuiz > 0 ? Math.round((individualQuiz / totalQuiz) * 100) : 0;

  const userPoolQuiz = courseModulePartProgress?.filter(
    (modulePart) =>
      modulePart?.modulePart?.type === 'QUIZ' &&
      (modulePart.status === 'FINISHED' || modulePart.status === 'STARTED'),
  ).length;
  const userPoolCompletion = totalQuiz > 0 ? userPoolQuiz / totalQuiz : 0;
  const userPoolQuizCompletionRate =
    totalPoolUsers > 0
      ? Math.round((userPoolCompletion / totalPoolUsers) * 100)
      : 0;

  return {
    individualQuizCompletionRate,
    userPoolQuizCompletionRate,
  };
};

export const getCourseProgress = (
  courseModulePartProgress,
  modulePartData,
  totalPoolUsers,
  coursePartData,
) => {
  const modulePartDataLength =
    modulePartData?.filter((mp) => mp?.courseModuleId)?.length || 0;
  // Overall course Progress
  const individualCourseProgressRate =
    coursePartData?.length > 0
      ? Math.round((modulePartDataLength / coursePartData?.length) * 100)
      : 0;

  const userPoolCompletion =
    coursePartData?.length > 0
      ? courseModulePartProgress.length / coursePartData?.length
      : 0;
  const userPoolCourseProgressRate =
    totalPoolUsers > 0
      ? Math.round((userPoolCompletion / totalPoolUsers) * 100)
      : 0;

  return {
    individualCourseProgressRate,
    userPoolCourseProgressRate,
  };
};

export const getAvergeLabPerformance = (
  courseModulePartProgressData,
  coursePartData,
) => {
  const completedLabs = courseModulePartProgressData?.filter(
    (modulePartProgress) =>
      modulePartProgress?.modulePart?.type === 'LAB' &&
      (modulePartProgress.status === 'FINISHED' ||
        modulePartProgress.status === 'STARTED'),
  ).length;

  const totalLabs = coursePartData?.filter(
    (coursePart) => coursePart.type === 'LAB',
  ).length;

  const avergaeLabCompletionRate =
    totalLabs > 0 ? Math.round((completedLabs / totalLabs) * 100) : 0;

  return {
    avergaeLabCompletionRate,
  };
};

export const getAvergeQuizPerformance = (
  quizAttemptsData,
  courseModuleParts,
) => {
  const succeededQuizAttemptsCount = quizAttemptsData?.filter(
    (quizAttempt) => quizAttempt.success,
  )?.length;
  const totalQuizAttemptsCount = quizAttemptsData?.length;

  const completedCourseQuizzes = _.groupBy(quizAttemptsData, 'quizId');

  const completedQuizCount = Object.keys(completedCourseQuizzes)?.length;

  const quizCount = courseModuleParts?.filter(
    (modulePart) => modulePart.type === 'QUIZ',
  )?.length;

  const averageQuizSuccessRate =
    totalQuizAttemptsCount > 0
      ? Math.round((succeededQuizAttemptsCount / totalQuizAttemptsCount) * 100)
      : 0;

  const averageQuizCompletionRate =
    quizCount > 0 ? Math.round((completedQuizCount / quizCount) * 100) : 0;

  return {
    averageQuizCompletionRate,
    averageQuizSuccessRate,
  };
};

export const getUserQuizData = (userQuizAttemptsData, courseUserId) => {
  const succeededQuizAttemptsCount = userQuizAttemptsData
    ?.filter((_qa) => _qa.courseUserId === courseUserId)
    ?.filter((quizAttempt) => quizAttempt.success)?.length;
  const totalQuizAttemptsCount = userQuizAttemptsData?.length;

  const individualQuizSuccessRate =
    totalQuizAttemptsCount > 0
      ? Math.round((succeededQuizAttemptsCount / totalQuizAttemptsCount) * 100)
      : 0;

  return {
    individualQuizSuccessRate,
  };
};
