import { USER_PERMISSION_TYPE } from '../../constants';

const userHasPermission = (user, permission) => {
  const permissionList =
    user?.signInUserSession?.accessToken?.payload?.['cognito:groups'] || [];
  switch (permission) {
    case USER_PERMISSION_TYPE.CREATE: {
      return permissionList.includes('create');
    }
    case USER_PERMISSION_TYPE.VIEW_INSIGHTS: {
      return permissionList.includes('view-insights');
    }
    case USER_PERMISSION_TYPE.MANAGE_TRAINING: {
      return permissionList.includes('manage-training');
    }
    case USER_PERMISSION_TYPE.MANAGE_GROUPS: {
      return permissionList.includes('manage-groups');
    }
    default:
      return false;
  }
};

const isAdminOrManager = (userType) => ['ADMIN', 'MANAGER'].includes(userType);

export { userHasPermission, isAdminOrManager };
