import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdTypography } from '@fifthdomain/fe-shared';

const SummaryTitle = ({
  data: { value, description, icon },
  titleVariant,
  subtitleVariant,
  justifyContent,
}) => (
  <Box display="flex" justifyContent="center" alignItems="center" mr={2}>
    {icon && <Box mr={1}>{icon}</Box>}
    <Box display="flex" flexDirection="column" ml={icon ? 1 : 0}>
      {value && (
        <Box display="flex" justifyContent={justifyContent} whiteSpace="nowrap">
          <FdTypography variant={titleVariant}>{value}</FdTypography>
        </Box>
      )}
      {description && (
        <Box display="flex" justifyContent={justifyContent}>
          <FdTypography variant={subtitleVariant} color="secondary">
            {description}
          </FdTypography>
        </Box>
      )}
    </Box>
  </Box>
);

SummaryTitle.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]),
    description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
    icon: PropTypes.element,
  }).isRequired,
  titleVariant: PropTypes.string,
  subtitleVariant: PropTypes.string,
  justifyContent: PropTypes.string,
};

SummaryTitle.defaultProps = {
  titleVariant: 'h3',
  subtitleVariant: 'subtitle2',
  justifyContent: 'center',
};

export default SummaryTitle;
