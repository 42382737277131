import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdCard, FdTextField } from '@fifthdomain/fe-shared';

const LabDetails = ({ Controller, control, heading, values }) => (
  <FdCard
    variant="outlined"
    heading={heading}
    subHeading="Create a name for this lab. This name will be visible to all participants. 
    Please also specify how long a participant has to complete the lab."
  >
    <Box display="flex" flexDirection="column">
      <Controller
        control={control}
        name="labName"
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <Box mt={1} mb={2}>
            <FdTextField
              id="labName"
              label="Lab Name"
              required
              fullWidth
              error={error}
              value={values?.labName}
              helperText={error && error.message}
              {...rest}
              inputRef={ref}
            />
          </Box>
        )}
      />
      <Box mt={0} mb={2}>
        <Controller
          control={control}
          name="labDescription"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id="labDescription"
                label="Lab Description"
                required
                fullWidth
                multiline
                value={values?.labDescription}
                rows={2}
                error={error}
                helperText={error && error.message}
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  </FdCard>
);

LabDetails.propTypes = {
  Controller: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  values: PropTypes.shape({
    labName: PropTypes.string,
    labDescription: PropTypes.string,
    labDuration: PropTypes.number,
  }),
};

LabDetails.defaultProps = {
  heading: undefined,
  values: {
    labName: '',
    labDescription: '',
    labDuration: 10,
  },
};

export default LabDetails;
