/* 
  Usage:-
  - add space for drawer - width in pixels
  setAppMarginRightByIds(['topnav'], rightHandSideDrawerWidth = '400px');

  - remove space for drawer - width in pixels
  setAppMarginRightByIds(['topnav'], rightHandSideDrawerWidth = '0');
*/
const setAppMarginRightByIds = (ids, width) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const id in ids) {
    if (document.getElementById(ids[id])) {
      document.getElementById(ids[id]).style.marginRight = width;
    }
  }
  if (document.getElementsByTagName('header')[0]) {
    document.getElementsByTagName('header')[0].style.paddingRight = width;
  }
};

export default setAppMarginRightByIds;

export const productStyle = (_product, typeStyle) => {
  return {
    padding: typeStyle === 'chip' ? '2px 4px 0 4px' : '12px 4px 6px 4px',
    borderRadius: '4px',
    color: '#000000',
    fontWeight: 500,
    backgroundColor:
      _product === 'ASSESSMENT'
        ? '#FFCDD2'
        : _product === 'COMPETITION'
        ? '#C5CAE9'
        : '#C8E6C9',
  };
};
