import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { FdTable, FdTypography } from '@fifthdomain/fe-shared';

const CourseUsers = ({ rows, actions, ...rest }) => {
  const { control } = useFormContext();
  const columns = [
    { field: 'name', width: 300, headerName: 'Name' },
    {
      field: 'email',
      width: 250,
      headerName: 'Email',
    },
    { field: 'type', width: 200, headerName: 'Role' },
  ];

  return (
    <Box height="450px">
      <Controller
        control={control}
        name="userIds"
        render={({ field: { value, onChange } }) => (
          <FdTable
            toolbarSettings={{
              title: (
                <Box>
                  <FdTypography variant="h3">Add Users</FdTypography>
                </Box>
              ),
              filterButton: true,
              searchBox: true,
            }}
            selection
            rows={rows}
            columns={columns}
            tablePageSize={5}
            actions={actions}
            selectionModel={value}
            onSelectionModelChange={onChange}
            gridId="labs-course-users"
            {...rest}
          />
        )}
      />
    </Box>
  );
};

CourseUsers.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

CourseUsers.defaultProps = {
  actions: [],
  headerActions: [],
};

export default CourseUsers;
