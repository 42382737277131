import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  FdProgress,
  useSnapshot,
  globalStore,
  Authorization,
} from '@fifthdomain/fe-shared';
import setAppMarginRightByIds from '../shared/utils/layout';
import { listProductsByOrgId } from '../graphql/queries';

const ProtectedManageContentRoute = ({ component: Component, ...rest }) => {
  const { user, loading, orgPricingTier, orgId, permissions } =
    useSnapshot(globalStore);
  const history = useHistory();

  const { data: orgProductsData, loading: orgProductsLoading } = useQuery(
    gql(listProductsByOrgId),
    {
      variables: {
        orgId,
      },
      skip: !orgId,
    },
  );

  const orgProducts = orgProductsData?.listProductsByOrgId?.items?.map(
    (orgProduct) => orgProduct?.product?.name,
  );

  const managerPermissions =
    orgPricingTier !== 'STARTER' &&
    user &&
    Authorization.canManageContent(permissions);

  if (loading || orgProductsLoading) return <FdProgress />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          if (managerPermissions) {
            setAppMarginRightByIds(['topnav', 'labs-fe'], '0'); // reset topnav and labs to full width on page initialize
            // check if the org has access to Labs
            if (
              Array.isArray(orgProducts) &&
              !orgProducts?.includes('Labs') &&
              /\/labs\/courses\/?/.test(window.location.href)
            ) {
              history.goBack();
            }
            return <Component {...rest} {...props} />;
          }
        }
        return null;
      }}
    />
  );
};

ProtectedManageContentRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ProtectedManageContentRoute;
