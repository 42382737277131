import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery, gql } from '@apollo/client';
import { Box } from '@material-ui/core';
import {
  FdTypography,
  FdProgress,
  setShowNav,
  VDIConsole,
  FdButton,
} from '@fifthdomain/fe-shared';
import { getPrototypeConsole, getLabPrototype } from '../graphql/queries';
import { TEST_LAB_STATUS } from '../constants';

const TestLabVDI = () => {
  const { labId, vmId } = useParams();

  useEffect(() => {
    setShowNav(false);
  }, []);

  const [fetchConsole, { loading: consoleLoading, data: consoleData }] =
    useLazyQuery(gql(getPrototypeConsole), {
      fetchPolicy: 'network-only',
    });

  const { loading: getLabPrototypeLoading } = useQuery(gql(getLabPrototype), {
    variables: {
      id: labId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      // Check if VM is in lab prototype
      const vmList = data?.getLabPrototype?.vms?.items?.filter(
        (vm) => vm.id === vmId,
      );

      // Get lab prototype status
      const status =
        TEST_LAB_STATUS[data?.getLabPrototype?.status] || 'NOT_DEFINED';
      if (vmList?.length > 0 && status === TEST_LAB_STATUS.TESTING) {
        // Set document title
        document.title = `${data?.getLabPrototype?.name} - ${vmList[0].name}`;
        fetchConsole({
          variables: {
            labPrototypeVmId: vmId,
          },
        });
      }
    },
  });

  if (getLabPrototypeLoading || consoleLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        <FdTypography variant="h4">Fetching MKS Ticket...</FdTypography>
        <Box m={1} />
        <FdProgress />
      </Box>
    );
  }
  return (
    <Box>
      {consoleData ? (
        <VDIConsole
          MKSTicket={consoleData?.getPrototypeConsole?.location}
          keepAwakeUrl={consoleData?.getPrototypeConsole?.keepAwakeUrl}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          height="100%"
        >
          <FdTypography variant="h4">Unable to acquire MKS ticket</FdTypography>
          <FdTypography variant="h4">
            Please verify that lab is in test mode
          </FdTypography>
          <Box m={4} />
          <FdButton size="large" onClick={() => window.location.reload()}>
            Retry
          </FdButton>
        </Box>
      )}
    </Box>
  );
};
export default TestLabVDI;
