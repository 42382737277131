export const updateCourseUserStatus = (
  modulePartProgress,
  updateCourseUserMutation,
  courseUserData,
  partId,
) => {
  const isAllModulePartsNotStarted =
    modulePartProgress?.listModulePartProgressesByCourseUserId?.items
      ?.filter((modulePart) => modulePart.modulePartId !== partId)
      .every((modulePart) => modulePart.status === 'NOT_STARTED');

  const isRemainingModulePartsCompleted =
    modulePartProgress?.listModulePartProgressesByCourseUserId?.items
      ?.filter((modulePart) => modulePart.modulePartId !== partId)
      .every((mp) => mp.status === 'FINISHED');

  updateCourseUserMutation({
    variables: {
      input: {
        id: courseUserData?.getCourseUser?.id,
        lastActive: new Date().toISOString(),
      },
    },
  });
  if (isAllModulePartsNotStarted) {
    updateCourseUserMutation({
      variables: {
        input: {
          id: courseUserData?.getCourseUser?.id,
          status: 'STARTED',
          lastActive: new Date().toISOString(),
        },
      },
    });

    return;
  }

  if (isRemainingModulePartsCompleted) {
    updateCourseUserMutation({
      variables: {
        input: {
          id: courseUserData?.getCourseUser?.id,
          status: 'FINISHED',
          lastActive: new Date().toISOString(),
        },
      },
    });
  }
};
