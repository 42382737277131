import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Checkbox } from '@material-ui/core';
import { FdTypography, FdTextField, FdAlert } from '@fifthdomain/fe-shared';
import QuestionPoints from './QuestionPoints';

const FreeTextQuestion = ({ qIdx, userGraded }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const competencyError = errors?.questions?.[qIdx]?.competencies?.message;

  return (
    <Box width="100%">
      {competencyError && (
        <Box mt={2} mb={2}>
          <FdAlert variant="error" message={competencyError} />
        </Box>
      )}
      <Box mt={2}>
        <Controller
          control={control}
          name={`questions[${qIdx}].question`}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id={`questions[${qIdx}].question`}
                label="Question"
                required
                fullWidth
                error={error}
                helperText={error && error.message}
                placeholder="Type your quiz question here"
                data-cy={`questions[${qIdx}].question`}
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
      </Box>
      {userGraded && (
        <Box>
          <Box mt={2}>
            <Controller
              control={control}
              name={`questions[${qIdx}].correctAnswer`}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id={`questions[${qIdx}].correctAnswer`}
                    label="Correct Answer"
                    required
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                    placeholder="Type your quiz answer here"
                    data-cy={`questions[${qIdx}].correctAnswer`}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Controller
              control={control}
              name={`questions[${qIdx}].caseSensitive`}
              render={({ field: { ref, onChange, value, ...fieldProps } }) => (
                <Box display="flex" alignItems="center">
                  <Checkbox
                    id={`questions[${qIdx}].caseSensitive`}
                    inputRef={ref}
                    checked={value}
                    onChange={onChange}
                    data-cy={`questions[${qIdx}].caseSensitive`}
                    {...fieldProps}
                  />
                  <FdTypography>Case sensitive</FdTypography>
                </Box>
              )}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Controller
              control={control}
              name={`questions[${qIdx}].whiteSpaceSensitive`}
              render={({ field: { ref, onChange, value, ...fieldProps } }) => (
                <Box display="flex" alignItems="center">
                  <Checkbox
                    id={`questions[${qIdx}].whiteSpaceSensitive`}
                    inputRef={ref}
                    checked={value}
                    onChange={onChange}
                    data-cy={`questions[${qIdx}].whiteSpaceSensitive`}
                    {...fieldProps}
                  />
                  <FdTypography>Whitespace sensitive</FdTypography>
                </Box>
              )}
            />
          </Box>
        </Box>
      )}
      <QuestionPoints qIdx={qIdx} userGraded={userGraded} />
    </Box>
  );
};

FreeTextQuestion.propTypes = {
  qIdx: PropTypes.number.isRequired,
  userGraded: PropTypes.bool,
};

FreeTextQuestion.defaultProps = {
  userGraded: true,
};

export default FreeTextQuestion;
