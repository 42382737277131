export const listCourseModulesByUserCourseId = /* GraphQL */ `
  query listCourseUsersByOrgId($orgId: ID!, $userId: ID!) {
    listCourseUsersByOrgId(orgId: $orgId, filter: { userId: { eq: $userId } }) {
      items {
        id
        course {
          name
          courseModules {
            items {
              id
              name
              parts {
                items {
                  name
                  id
                  type
                  description
                  file {
                    key
                  }
                  lab {
                    status
                    id
                    status
                    updatedAt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getCourseUser = /* GraphQL */ `
  query getCourseUser($userCourseId: ID!) {
    getCourseUser(id: $userCourseId) {
      id
      course {
        id
        name
        description
        courseModules {
          items {
            id
            name
            orderNumber
            description
            createdAt
            duration
            parts(filter: { status: { ne: DELETED } }) {
              items {
                duration
                expiry
                url
                status
                inactivityExpiry
                initialLabCount
                minLabCount
                orderNumber
                lab {
                  status
                  id
                  updatedAt
                }
                id
                type
                description
                name
                file {
                  bucket
                  key
                  region
                }
                quiz {
                  id
                  description
                  name
                  duration
                  multipleAttempts
                  showResults
                  graded
                }
              }
            }
          }
        }
        courseUsers {
          items {
            user {
              id
            }
          }
        }
      }
      user {
        id
      }
      courseProgress
      status
    }
  }
`;

export const listQuizzesByOrgId = /* GraphQL */ `
  query listQuizzesByOrgId($orgId: ID!, $limit: Int, $nextToken: String) {
    listQuizzes(
      filter: { orgId: { eq: $orgId } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        duration
      }
      nextToken
    }
  }
`;

export const listUsersByOrgId = /* GraphQL */ `
  query listUsersByOrgId($orgId: ID!, $limit: Int, $nextToken: String) {
    listUsersByOrgId(orgId: $orgId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        type
      }
      nextToken
    }
  }
`;

export const listGroups = /* GraphQL */ `
  query listGroups {
    listGroups {
      items {
        id
        name
        description
        users {
          items {
            id
          }
        }
      }
    }
  }
`;

export const getCourseForEdit = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      orgId
      name
      description
      categoryId
      available
      category {
        name
        id
        createdAt
        updatedAt
      }
      ownerId
      availability
      status
      image {
        key
        bucket
        region
      }
      courseModules {
        items {
          name
          description
          courseId
          orderNumber
          id
          duration
          parts(filter: { status: { ne: DELETE_REQUESTED, ne: DELETED } }) {
            items {
              status
              id
              name
              description
              orderNumber
              type
              file {
                key
                region
                bucket
              }
              labId
              url
              lab {
                id
                name
                provider
                status
                userId
                public
                shared
                orgId
                createdAt
                updatedAt
              }
              duration
              expiry
              inactivityExpiry
              initialLabCount
              deletionMode
              minLabCount
              quizId
              quiz {
                name
              }
            }
          }
          createdAt
          updatedAt
          courseCourseModulesId
        }
        nextToken
      }
      courseUsers {
        items {
          id
        }
      }
      coursegroups {
        items {
          id
        }
      }
      id
      createdAt
      updatedAt
    }
  }
`;

export const getCourseUsersGroups = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      status
      courseModules {
        items {
          id
          parts {
            items {
              id
              status
              type
              labInstances {
                items {
                  id
                  userId
                }
              }
            }
          }
        }
        nextToken
      }
      courseUsers {
        items {
          id
          user {
            name
            id
            email
            groups {
              items {
                group {
                  name
                }
              }
            }
          }
          messages(filter: { seen: { eq: FALSE } }) {
            items {
              id
              message
              userCourseId
              sender {
                id
                type
                email
                name
              }
              createdAt
            }
          }
          updatedAt
        }
      }
      coursegroups {
        items {
          id
          group {
            id
            name
            users {
              items {
                id
                user {
                  name
                  id
                  email
                }
              }
            }
          }
        }
      }
      invitedUsers(filter: { accepted: { eq: false } }) {
        items {
          id
          email
          group {
            name
          }
          updatedAt
        }
      }
    }
  }
`;

export const getAllCoursesWithQuizByOrgId = /* GraphQL */ `
  query ListCoursesByOrgId($orgId: ID!, $limit: Int, $nextToken: String) {
    listCoursesByOrgId(orgId: $orgId, limit: $limit, nextToken: $nextToken) {
      items {
        courseModules {
          items {
            parts(filter: { type: { eq: QUIZ } }) {
              items {
                quiz {
                  id
                  name
                }
              }
            }
          }
        }
        id
        name
      }
      nextToken
    }
  }
`;

export const getQuizForParticipant = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      questions {
        items {
          id
          quizId
          type
          name
          point
          answer
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
          orderNumber
          options {
            items {
              id
              optionName
              orderNumber
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listLabInstancesRemainingTime = /* GraphQL */ `
  query ListLabInstances(
    $filter: ModelLabInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        labPrototypeId
        userId
        runningTime
      }
      nextToken
    }
  }
`;

export const getLabModulePart = /* GraphQL */ `
  query GetModulePart($id: ID!) {
    getModulePart(id: $id) {
      description
      name
      labId
      lab {
        vms {
          items {
            id
            name
          }
        }
        status
      }
      expiry
    }
  }
`;

export const listCourseUsersForMessages = /* GraphQL */ `
  query ListCourseUsersForMessages(
    $filter: ModelCourseUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        course {
          name
          courseModules {
            items {
              id
              parts {
                items {
                  id
                  status
                }
              }
            }
            nextToken
          }
        }
        messages(filter: { seen: { eq: FALSE } }) {
          items {
            id
            message
            userCourseId
            sender {
              id
              type
              email
              name
              permissions
            }
            createdAt
          }
        }
      }
      nextToken
    }
  }
`;

export const listModulePartProgresses = /* GraphQL */ `
  query ListModulePartProgresses(
    $filter: ModelModulePartProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModulePartProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseUserId
        courseUser {
          courseId
          userId
          orgId
          status
          courseProgress
          id
          createdAt
          updatedAt
          courseCourseUsersId
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          status
          type
          quizId
          labId
          deletionMode
          url
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        startedOn
        finishedOn
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
