import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {
  Box,
  makeStyles,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { FdIcons } from '@fifthdomain/fe-shared';
import PartIcon from './Modules/PartIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listItem: {
    background: theme.palette.background.paper,
    margin: '5px 0',
  },
  listItemText: {
    wordBreak: 'break-all',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    background: theme.palette.background.paper,
    margin: '10px 0',
  },
  listIcon: {
    minWidth: '38px',
  },
  highlighted: {
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
  },
}));

const ModuleSidebar = ({ modules, currentPartId, onSidebarSelection }) => {
  const classes = useStyles();
  const { ExpandLess, ExpandMore } = FdIcons;
  const ModuleList = ({ module }) => {
    const defaultOpen = !!module?.parts?.items.find(
      (p) => p.id === currentPartId,
    );
    const [open, setOpen] = useState(defaultOpen);
    return (
      <>
        <ListItem
          button
          onClick={() => setOpen(!open)}
          className={classes.listItem}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
          <Box ml={1}>
            <ListItemText
              primary={module.name}
              className={classes.listItemText}
            />
          </Box>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {module?.parts?.items.map((p) => {
              if (
                p.status === 'DELETED' ||
                (p.type === 'LAB' &&
                  !['EXPIRED', 'READY'].includes(p.lab.status))
              )
                return null;

              return (
                <ListItem
                  button
                  className={`${classes.nested} ${
                    p.id === currentPartId && classes.highlighted
                  }`}
                  onClick={() => onSidebarSelection(module.id, p.id)}
                  key={shortid.generate()}
                >
                  <ListItemIcon className={classes.listIcon}>
                    <PartIcon type={p.type} />
                  </ListItemIcon>
                  <ListItemText
                    primary={p.name}
                    className={classes.listItemText}
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  };

  ModuleList.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    module: PropTypes.object.isRequired,
  };

  return (
    <Box>
      <List component="nav" className={classes.root}>
        <ListItem button>
          <ListItemText
            primary="Course Overview"
            className={classes.listItemText}
          />
        </ListItem>
        {modules.map((m) => (
          <ModuleList module={m} key={shortid.generate()} />
        ))}
      </List>
    </Box>
  );
};

ModuleSidebar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modules: PropTypes.array.isRequired,
  currentPartId: PropTypes.string.isRequired,
  onSidebarSelection: PropTypes.func.isRequired,
};

export default ModuleSidebar;
