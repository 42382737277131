import { QUESTION_MAPPING } from '../../constants';

// create or update questions
export const createUpdateQuestions = async ({
  createQuestionMutation,
  updateQuestionMutation,
  deleteQuestionMutation,
  createQuestionOptionMutation,
  updateQuestionOptionMutation,
  deleteQuestionOptionMutation,
  createCompetencyQuestionsMutation,
  deleteCompetencyQuestionsMutation,
  values,
  quizId,
  setValue,
}) => {
  const { questions, deletedQuestions } = values;
  // add questions to quiz
  await Promise.all(
    questions.map(async (question, qIdx) => {
      const actionQuestionMutation = question?.questionId
        ? updateQuestionMutation
        : createQuestionMutation;
      const actionQuestionId = question?.questionId
        ? { id: question.questionId }
        : undefined;
      const {
        question: questionName,
        questionType,
        caseSensitive,
        points,
        whiteSpaceSensitive,
        correctAnswer,
        order,
      } = question;
      // create questions
      await actionQuestionMutation({
        variables: {
          input: {
            quizId,
            type: QUESTION_MAPPING[questionType],
            caseSensitive,
            name: questionName,
            point: points || 1,
            whiteSpaceSensitive,
            answer: questionType === 'Free Text' ? correctAnswer : '',
            orderNumber: order,
            ...actionQuestionId,
          },
        },
        onCompleted: async (_questionData) => {
          const actionQuestion = question.questionId
            ? 'updateQuestion'
            : 'createQuestion';
          const questionId = _questionData?.[actionQuestion]?.id;
          if (!question.questionId) {
            setValue(`questions[${qIdx}].questionId`, questionId);
          }
          // delete removed competencies
          await Promise.all(
            question.deletedCompetencies.map((cd) =>
              deleteCompetencyQuestionsMutation({
                variables: {
                  input: { id: cd },
                },
              }),
            ),
          );
          // create newly added competencies
          const competenciesForCreation = question.competencies?.filter(
            (c) => !c.cDbId,
          );
          await Promise.all(
            competenciesForCreation.map((cc) =>
              createCompetencyQuestionsMutation({
                variables: {
                  input: {
                    competencyId: cc.competencyId,
                    questionId,
                    categoryId: cc.categoryId,
                    workRoleId: cc.workRoleId,
                    areaId: cc.areaId,
                  },
                },
              }),
            ),
          );

          // if not FREE_TEXT then create answer choices
          if (questionType !== 'Free Text') {
            const choices = question.multipleChoices;
            // delete removed choices
            await Promise.all(
              question.deletedChoices.map((dc) =>
                deleteQuestionOptionMutation({
                  variables: {
                    input: { id: dc },
                  },
                }),
              ),
            );

            // create or update choice options
            await Promise.all(
              choices.map(async (choice, cIdx) => {
                const actionAnswerMutation = choice?.choiceId
                  ? updateQuestionOptionMutation
                  : createQuestionOptionMutation;
                const actionOptionId = choice?.choiceId
                  ? { id: choice.choiceId }
                  : undefined;
                const {
                  answer: optionName,
                  order: orderNumber,
                  correctAnswer: optionCorrectAnswer,
                } = choice;
                await actionAnswerMutation({
                  variables: {
                    input: {
                      questionId,
                      questionOptionType: QUESTION_MAPPING[questionType],
                      optionName,
                      orderNumber,
                      correctAnswer: optionCorrectAnswer,
                      ...actionOptionId,
                    },
                    onCompleted: async (_answerData) => {
                      const actionOption = question.questionId
                        ? 'updateQuestionOption'
                        : 'createQuestionOption';
                      const choiceId = _answerData?.[actionOption]?.id;
                      if (!choice.choiceId) {
                        setValue(
                          `questions[${qIdx}].multipleChoices[${cIdx}].choiceId`,
                          choiceId,
                        );
                      }
                    },
                  },
                });
              }),
            );
          }
        },
      });
    }),
  );
  // delete removed questions
  await Promise.all(
    deletedQuestions.map(async (qId) => {
      await deleteQuestionMutation({
        variables: {
          input: {
            id: qId,
          },
        },
      });
    }),
  );
};
