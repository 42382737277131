import Yup from '../Common';

export const initialValuesDetails = {
  quizName: '',
  quizDescription: '',
  quizTimeLimit: '',
  allowMultipleAttempts: false,
  returnResultsToUsers: false,
  notGradeUsers: false,
};

export const initialValuesQuestions = {
  questions: [],
};

export const validationSchemaDetails = {
  quizId: Yup.string(),
  quizName: Yup.string()
    .required('Please enter a quiz name')
    .max(25, 'Quiz name must be 25 characters or less')
    .noWhitespace('Please enter a quiz name'),
  quizDescription: Yup.string()
    .max(80, 'Quiz description must be 80 characters or less')
    .noWhitespace('Please enter a valid quiz description'),
  quizTimeLimit: Yup.string().test(
    'is-decimal',
    'Please enter a positive, whole number',
    (value) => !`${value}`.match(/^\d*\.{1}\d*$/), // Check for decimal only
  ),
  allowMultipleAttempts: Yup.bool(),
  returnResultsToUsers: Yup.bool(),
  notGradeUsers: Yup.bool(),
};

export const validationSchemaQuestions = (graded) => ({
  questions: Yup.array()
    .of(
      Yup.object().shape({
        questionId: Yup.string(),
        questionType: Yup.string().required('Please select a question type'),
        question: Yup.string()
          .required('Please enter a question')
          .noWhitespace('Please enter a question'),
        correctAnswer: Yup.lazy(() => {
          if (graded) {
            return Yup.string().when(['questionType'], {
              is: (questionType) => {
                return questionType === 'Free Text';
              },
              then: Yup.string()
                .required('Please enter a correct answer')
                .noWhitespace('Please enter a correct answer'),
              otherwise: Yup.string().nullable(),
            });
          }
          return Yup.mixed().notRequired();
        }),
        points: Yup.lazy(() => {
          if (graded) {
            return Yup.number()
              .typeError('Please enter a positive, whole number')
              .required('Please enter a positive, whole number')
              .positive('Please enter a positive, whole number')
              .min(1, 'Please enter a positive, whole number');
          }
          return Yup.mixed().notRequired();
        }),
        caseSensitive: Yup.bool().default(false).nullable(),
        whiteSpaceSensitive: Yup.bool().default(false).nullable(),
        order: Yup.number(),
        competencies: Yup.array()
          .of(
            Yup.object().shape({
              competency: Yup.string(),
              id: Yup.string(),
              competencyId: Yup.string(),
              categoryId: Yup.string(),
              workRoleId: Yup.string(),
              areaId: Yup.string(),
            }),
          )
          .min(1, 'Please assign a competency'),
        multipleChoices: Yup.lazy(() => {
          if (graded) {
            return Yup.array().when(['questionType'], {
              is: (questionType) => questionType !== 'Free Text',
              then: Yup.array()
                .of(
                  Yup.object().shape({
                    choiceId: Yup.string(),
                    answer: Yup.string()
                      .required('Please state the option')
                      .noWhitespace('Please state the option'),
                    correctAnswer: Yup.bool().default(false).nullable(),
                    order: Yup.number(),
                  }),
                )
                .min(1, 'Please add an option')
                .test(
                  'correctOptionSelectionMultiple',
                  'Please select the correct option(s)',
                  (choices, ctx) =>
                    ctx.options.context?.validateOnSaveOnly &&
                    ctx.parent.questionType === 'Multiple Choice' &&
                    choices.filter((c) => c.answer).length > 0
                      ? choices.filter((c) => c.correctAnswer).length > 0
                      : true,
                )
                .test(
                  'correctOptionSelectionSingle',
                  'Please select the correct option',
                  (choices, ctx) =>
                    ctx.options.context?.validateOnSaveOnly &&
                    ctx.parent.questionType === 'Single Choice' &&
                    choices.filter((c) => c.answer).length > 0
                      ? choices.filter((c) => c.correctAnswer).length === 1
                      : true,
                ),
              otherwise: Yup.array().of(
                Yup.object().shape({
                  choiceId: Yup.string(),
                  answer: Yup.string(),
                  correctAnswer: Yup.bool(),
                  order: Yup.number(),
                }),
              ),
            });
          }
          return Yup.array().when(['questionType'], {
            is: (questionType) => questionType !== 'Free Text',
            then: Yup.array()
              .of(
                Yup.object().shape({
                  choiceId: Yup.string(),
                  answer: Yup.string()
                    .required('Please state the option')
                    .noWhitespace('Please state the option'),
                  correctAnswer: Yup.bool().default(false).nullable(),
                  order: Yup.number(),
                }),
              )
              .min(1, 'Please add an option'),
          });
        }),
        deletedCompetencies: Yup.array().of(Yup.string()),
        deletedChoices: Yup.array().of(Yup.string()),
      }),
    )
    .min(1, 'Please add a valid question'),
  deletedQuestions: Yup.array().of(Yup.string()),
});
