import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Box } from '@material-ui/core';
import {
  FdTypography,
  FdSelect,
  FdDelayed,
  FdAlert,
} from '@fifthdomain/fe-shared';
import FdTextView from '../../FdTextView';

const LabPart = ({ moduleIdx, partIdx, isEdit, allLabs }) => {
  const { control, getValues } = useFormContext();

  const { isLabTransferred } = getValues('modules')[moduleIdx].parts[partIdx];

  return (
    <Box>
      <Controller
        control={control}
        name={`modules[${moduleIdx}].parts[${partIdx}].lab`}
        render={({
          field: { ref, value: fieldValue, ...rest },
          fieldState: { error },
        }) => (
          <FdDelayed>
            <Box mb={2}>
              {isEdit && !isLabTransferred ? (
                <FdSelect
                  id={`modules[${moduleIdx}].parts[${partIdx}].lab`}
                  label="Lab"
                  options={allLabs?.map((l) => l.name) || []}
                  defaultSelected={fieldValue}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  data-cy={`modules[${moduleIdx}].parts[${partIdx}].lab`}
                  placeholder="Select a Lab"
                  {...rest}
                  inputRef={ref}
                />
              ) : (
                <FdTextView label="Lab" value={fieldValue} />
              )}
            </Box>
          </FdDelayed>
        )}
      />
      <Box my={1}>
        <FdAlert
          variant="info"
          message={
            <FdTypography variant="body2">
              All labs in lab course parts have a duration of 2 hours.
              Participants can extend their lab time up to 2 times, giving them
              a maximum of six hours per lab. Participants can also reset their
              labs if needed, deploying new lab instances and erasing all
              progress. Note that resetting a lab does not reset a participant’s
              remaining lab time or the number of lab extensions they have.
            </FdTypography>
          }
        />
      </Box>
    </Box>
  );
};

LabPart.propTypes = {
  allLabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  moduleIdx: PropTypes.string.isRequired,
  partIdx: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default LabPart;
