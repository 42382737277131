import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdAccordion,
  FdTypography,
  FdButton,
  FdTextField,
  FdCheckbox,
} from '@fifthdomain/fe-shared';
import { useFieldArray } from 'react-hook-form';
import FdBoxButton from '../FdBoxButton';

const LabNetworks = ({
  Controller,
  control,
  heading,
  getValueNetwork,
  getValueLabNetwork,
  setValueNetwork,
}) => {
  const {
    fields: labNetworkFields,
    remove: labNetworkRemove,
    append: labNetworkAppend,
  } = useFieldArray({
    control,
    name: 'labNetworks',
  });

  return (
    <FdCard
      variant="outlined"
      optional
      heading={heading}
      subHeading="Specify the network(s) that you’d like to add to this lab."
    >
      {labNetworkFields.map((item, index) => {
        return (
          <Box my={2} key={item.id}>
            <FdAccordion
              open
              startAdornment
              showToggleButton={false}
              summary={() => (
                <Box style={{ width: '100%' }}>
                  <Box display="flex">
                    <Box sx={{ flexGrow: 1 }}>
                      <FdTypography variant="h3">Network</FdTypography>
                    </Box>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        labNetworkRemove(index);
                        // Delete Mutation
                        setValueNetwork(
                          'labDeleteNetworks',
                          getValueNetwork.concat(
                            getValueLabNetwork?.filter(
                              (labNetw) => labNetw.name === item.name,
                            ),
                          ),
                        );
                      }}
                    >
                      DELETE
                    </FdButton>
                  </Box>
                </Box>
              )}
              content={() => (
                <Box display="flex" flexDirection="column">
                  <Controller
                    control={control}
                    name={`labNetworks[${index}].name`}
                    render={({
                      field: { ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <Box mt={1} mb={2}>
                        <FdTextField
                          id={`labNetworks[${index}].name`}
                          label="Network Name"
                          placeholder="Network Name"
                          required
                          fullWidth
                          error={error}
                          helperText={error && error.message}
                          {...rest}
                          inputRef={ref}
                        />
                      </Box>
                    )}
                  />
                  <Box mt={0} mb={3}>
                    <Controller
                      control={control}
                      name={`labNetworks[${index}].cidr`}
                      render={({
                        field: { ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <Box mt={1} mb={2}>
                          <FdTextField
                            id={`labNetworks[${index}].cidr`}
                            label="CIDR"
                            placeholder="000.000.000.000/00"
                            required
                            fullWidth
                            error={error}
                            helperText={error && error.message}
                            {...rest}
                            inputRef={ref}
                          />
                        </Box>
                      )}
                    />
                  </Box>

                  <Box mt={0} mb={1}>
                    <Controller
                      control={control}
                      name={`labNetworks[${index}].hasInternet`}
                      render={({
                        field: { ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <Box mt={1} mb={2}>
                          <FdCheckbox
                            name={`labNetworks[${index}].hasInternet`}
                            label="Enable internet for VM"
                            checked={rest.value}
                            error={error}
                            {...rest}
                            inputRef={ref}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
              )}
            />
          </Box>
        );
      })}
      <FdBoxButton
        onClick={async (e) => {
          e.preventDefault();
          labNetworkAppend({});
        }}
      >
        ADD NETWORK
      </FdBoxButton>
    </FdCard>
  );
};

LabNetworks.propTypes = {
  Controller: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  setValueNetwork: PropTypes.func,
  getValueNetwork: PropTypes.arrayOf(
    PropTypes.shape({
      cidr: PropTypes.string,
      createdAt: PropTypes.string,
      labPrototypeId: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  ),
  getValueLabNetwork: PropTypes.arrayOf(PropTypes.object),
};

LabNetworks.defaultProps = {
  heading: undefined,
  setValueNetwork: () => {},
  getValueNetwork: [],
  getValueLabNetwork: [],
};

export default LabNetworks;
