import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdTypography,
  FdAccordion,
  FdCheckbox,
} from '@fifthdomain/fe-shared';

const ViewNetworks = ({ heading, subHeading, values }) => (
  <FdCard heading={heading} subHeading={subHeading} variant="outlined">
    <Box display="flex" flexDirection="column">
      {values?.labNetworks?.map((labNetwork) => (
        <Box mt={2} key={labNetwork.id}>
          <FdAccordion
            open
            startAdornment
            showToggleButton={false}
            summary={() => (
              <Box display="flex" flexDirection="row">
                <FdTypography variant="h3">Network</FdTypography>
              </Box>
            )}
            content={() => (
              <>
                <Box mt={2} mb={2}>
                  <FdTypography variant="subtitle1">Network Name</FdTypography>
                  <FdTypography variant="body1" color="secondary">
                    {labNetwork?.name}
                  </FdTypography>
                </Box>
                <Box mt={2} mb={2}>
                  <FdTypography variant="subtitle1">CIDR</FdTypography>
                  <FdTypography variant="body1" color="secondary">
                    {labNetwork?.cidr}
                  </FdTypography>
                </Box>
                <Box mt={2} mb={2}>
                  <FdCheckbox
                    label="Enable internet for VM"
                    checked={labNetwork?.hasInternet}
                    disabled
                  />
                </Box>
              </>
            )}
          />
        </Box>
      ))}
    </Box>
  </FdCard>
);

ViewNetworks.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subHeading: PropTypes.string,
  values: PropTypes.shape({
    labNetworks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        cidr: PropTypes.string,
      }),
    ),
  }).isRequired,
};

ViewNetworks.defaultProps = {
  heading: '',
  subHeading: '',
};

export default ViewNetworks;
